import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom'; 

const Navbar = () => {
  const [isSticky, setSticky] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const location = useLocation(); 
  const prevLocationRef = useRef();
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'dark'); // Default to dark theme if no theme is set

  useEffect(() => {
    const handleScroll = () => {
      setSticky(window.scrollY > 0);
    };
    window.addEventListener('scroll', handleScroll);

    // Load theme from local storage
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      setTheme(savedTheme);
      document.body.className = savedTheme;
    } else {
      // If no theme is set, default to dark theme
      setTheme('dark');
      localStorage.setItem('theme', 'dark');
      document.body.className = 'dark';
    }

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    // Scroll to top if location changes
    if (location !== prevLocationRef.current) {
      window.scrollTo(0, 0);
      prevLocationRef.current = location;
    }
  }, [location]);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = () => {
    setMenuOpen(false); 
  };

  const isActive = (path) => {
    return location.pathname === path ? 'active' : '';
  };  

  // Toggle theme and save to local storage
  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
    document.body.className = newTheme;
  };

  return (
    <>
      <nav className={`${isSticky ? 'sticky' : ''} ${isMenuOpen ? 'menu-open' : ''} z-50` }>
        <Link to="/">
          <div className="nav-logo flex items-center">
            <img src="../assets/images/isha_logo.png" alt="" />
            <p id='navbar-text' className='mt-2'>Isha Helpline Caregiver <br />Training & Research Center</p>
          </div>
        </Link>  
        <div className={`pages ${isMenuOpen ? 'open' : ''}`}>
          <Link to="/about" className={isActive('/about')} onClick={handleLinkClick}>About Us</Link>
          <Link to="/courses" className={isActive('/courses')} onClick={handleLinkClick}>Courses</Link>
          <Link to="/SuccessResult" className={isActive('/success')} onClick={handleLinkClick}>Success Result</Link>
          <Link to="/enquiry" className={isActive('/enquiry')} onClick={handleLinkClick}>Enquiry</Link>
          <div className="dropdown z-50" id="servicesDropdown">
            <Link>Explore</Link>
            <div className="dropdown-content">
              <Link to="/appointment" className={isActive('/appointment')} onClick={handleLinkClick}>Appointment</Link>
              {/* <Link to="/enrollment" className={isActive('/enrollment')} onClick={handleLinkClick}>Enrollment</Link> */}
              <Link to="/blog" className={isActive('/blog')} onClick={handleLinkClick}>Blog</Link>
              <Link to="/gallery" className={isActive('/gallery')} onClick={handleLinkClick}>Gallery</Link>
            </div>
          </div>
          {/* Toggle switch for theme */}
          <div className="theme-switch-wrapper">
            <label className="theme-switch" htmlFor="theme-switch-checkbox">
              <input type="checkbox" id="theme-switch-checkbox" onChange={toggleTheme} checked={theme === 'dark'} />
              <div className="slider round"></div>
            </label>
          </div>
        </div>
        <input type="checkbox" id="check" className="checkbtn" checked={isMenuOpen} onChange={toggleMenu} />
        <label htmlFor="check" className="checkbtn-label">
          <i className="fa-solid fa-bars"></i>
        </label>
      </nav>
    </>
  );
};

export default Navbar;
