import React from 'react';
import { Link, useLocation } from 'react-router-dom'; 

const CourseDetail5 = () => {
  return (
    <>  
    <div className="container py-5">
      <div className="row g-5">
        <div className="col-lg-8">
          {/* Course Detail Start */}
          <div className="mb-5">
            <img className="img-fluid w-100 rounded mb-5" src="../assets/images/blog-2.jpg" alt="" />
            <h1 className="mb-4 courses-details-text" >Introduction to React Development</h1>
            <h3 className='courses-details-text'>About the Course:</h3>
            <p>This course is designed to provide a comprehensive introduction to React development. You will learn the fundamental concepts of React and build practical projects to solidify your understanding.</p>
            <h3 className='courses-details-text'>What You'll Learn:</h3>
            <ul>
              <li>Setting up a React development environment</li>
              <li>Understanding JSX syntax</li>
              <li>Working with components and props</li>
              <li>Managing state with hooks</li>
              <li>Handling form input and validation</li>
              <li>Routing with React Router</li>
              <li>Fetching data from APIs</li>
              <li>Deploying React applications</li>
            </ul>
            <h3 className='courses-details-text'>Prerequisites:</h3>
            <ul>
              <li>Basic knowledge of HTML, CSS, and JavaScript</li>
              <li>Familiarity with ES6 syntax</li>
            </ul>
            {/* <button className="btn btn-primary btn-lg">Enroll Now</button> */}
          </div>
          {/* Course Detail End */}
        </div>
        {/* Sidebar Start */}
        <div className="col-lg-4">
          {/* About the Course Start */}
          <div className="mb-5">
            <h4 className="d-inline-block text-primary text-uppercase border-bottom   mb-4">About the Course</h4>
            <div className="bg-light rounded p-3">
              <h5>This Course Includes:</h5>
              <ul className="list-unstyled">
                <li><i className="fas fa-check-circle text-success me-2"></i>Interactive Lessons</li>
                <li><i className="fas fa-check-circle text-success me-2"></i>Hands-on Projects</li>
                <li><i className="fas fa-check-circle text-success me-2"></i>Quizzes and Assessments</li>
                <li><i className="fas fa-check-circle text-success me-2"></i>Expert Instructor Support</li>
              </ul>
              <Link to='/enrollment'><button className="btn btn-primary btn-lg mt-3" id='enrollment-button'>Enroll Now</button></Link>
            </div>
          </div>
          {/* About the Course End */}
          {/* Top Courses Start */}
          <div className="mb-5">
            <h4 className="d-inline-block text-primary text-uppercase border-bottom   mb-4">Top Courses</h4>
            <div className="d-flex flex-column">
              <Link className="h5 bg-light rounded py-2 px-3 mb-2 details-link" to="/Course1">Certificate I affiliated to CTEVT</Link>
              <Link className="h5 bg-light rounded py-2 px-3 mb-2 details-link" to="/Course2">Basic CNA (Certified Nursing Assistant) Certificate II</Link>
              <Link className="h5 bg-light rounded py-2 px-3 mb-2 details-link" to="/Course3">Certificate III in Community Service</Link>
              <Link className="h5 bg-light rounded py-2 px-3 mb-2 details-link" to="/Course4">Certificate IV in Individual Support (Supervisor Level)</Link>
            </div>
          </div>
          {/* Top Courses End */}
          {/* Image Start */}
          <div className="mb-5">
            <img src="../assets/images/blog-2.jpg" alt="" className="img-fluid rounded" />
          </div>
          {/* Image End */}
        </div>
        {/* Sidebar End */}
      </div>
    </div>
    {/* Course End */}
    
  {/* </div> */}
  </>
);
};

export default CourseDetail5;