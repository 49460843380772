import React, { useState } from "react";

const Contact = () => {
  const initialFormData = {
    name: "",
    email: "",
    phone: "",
    message: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [message, setMessage] = useState(""); // State for feedback message
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false); // State for loading status

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true); // Start loading when form is submitted

    fetch("https://backend.ishahelpline.org/message.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false); // Stop loading when response is received
        if (data.status === "success") {
          setMessage("Your message has been sent successfully!"); // Set success message
          setShowPopup(true); // Show success popup
          setFormData(initialFormData); // Reset form
        } else {
          setMessage(data.message || "Something went wrong. Please try again."); // Set error message
        }
      })
      .catch((error) => {
        setLoading(false); // Stop loading if error occurs
        setMessage("An error occurred. Please try again."); // Set error message
        console.error("Error:", error);
      });
  };

  return (
    <>
      <section class="contact-details">
        <a href="tel:15913135" class="contact-links">
          <div class="contact-container">
            <p>
              <i class="fa-solid fa-phone"></i> Phone
            </p>
            <p class="text-color">+977-1-5913135</p>
          </div>
        </a>
        <a href="mailto:ishahelpline@gmail.com" class="contact-links">
          <div class="contact-container">
            <p>
              <i class="fa-solid fa-envelope"></i> Email
            </p>
            <p class="text-color">ishahelpline@gmail.com</p>
          </div>
        </a>

        <div id="quote"></div>
      </section>

      <section className="contact-map-form">
        <div className="contact-map">
          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3532.4522562990046!2d85.31977757550939!3d27.70331937618484!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19853c799add%3A0xff61fada02a7a5ab!2sIsha%20Helpline%20Caregiver%20Training%20%26%20Research%20Center!5e0!3m2!1sen!2snp!4v1706986036572!5m2!1sen!2snp"
            width="600"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <section className="contact-form">
          <h2>Contact Us</h2>
          <form onSubmit={handleSubmit}>
            <div className="contact-form-content">
              <div className="contact-form-name">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Enter Your Name"
                  className="bg-light text-black"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="contact-form-email">
                <label htmlFor="email">E-mail</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Enter Your Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="contact-form-number">
                <label htmlFor="phone">Phone Number</label>
                <input
                  type="tel"
                  name="phone"
                  id="phone"
                  placeholder="Enter Your Phone Number"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </div>

              <div className="contact-form-message">
                <label htmlFor="message">Ask a question</label>
                <textarea
                  name="message"
                  id="message"
                  placeholder="Your Question"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  className="w-full rounded-md"
                ></textarea>
              </div>
              <button type="submit" disabled={loading}>
                {loading ? "Sending..." : "Submit"}
              </button>
            </div>
          </form>
          {message && <p>{message}</p>}
        </section>
        {showPopup && (
          <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-75">
            <div className="bg-white p-8 rounded shadow-md duration-300 hover:scale-105 hover:shadow-xl">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="mx-auto mt-8 h-16 w-16 text-green-400"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
              <h1 className="mt-2 text-center text-2xl font-bold text-gray-500">
                Success
              </h1>
              <p className="my-4 text-center text-sm text-gray-500">{message}</p>
              <div className="space-x-4 bg-gray-100 py-4 text-center">
                <button
                  className="inline-block rounded-md bg-green-500 px-6 py-2 font-semibold text-green-100 shadow-md duration-75 hover:bg-green-400"
                  onClick={() => setShowPopup(false)}
                >
                  Okay
                </button>
              </div>
            </div>
          </div>
        )}
      </section>

      <div class="col-lg-7" style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', width: '100', margin: '40px auto 0 auto', }}>
                    <div class="mb-4 home-about-text">
                        {/* <h5 class="d-inline-block text-primary text-uppercase border-bottom  ">Our Branches</h5> */}
                        {/* <h1 class="display-4">Premier Provider of Geriatric Caregiver Training</h1> */}
                    </div>
                    </div>
                    <div className="citem1 flex flex-col justify-center items-center mx-auto">
                        <h3 className="cheading">Kathmandu</h3>
                        <p>Address: Putalisadak-32, Kathmandu, Nepal</p>
                        <p>Phone: +977-1-5913135</p>
                        <p>Email: ishahelpline@gmail.com</p>
                        
                        <span className="cdate">Head Office</span>
                        {/* <span className="cdate">Our Official Branches</span> */}
                        {/* <span className="ccircle"></span> */}
                    </div>
                    <div className="flex justify-center">

                    <h2 className=" text-center text-[15px] cdate1 py-2 text-white px-6">Our Official Branches</h2>
                    </div>
      <section className="branches">
      <div className="cmain">
            <div className="ccontainer">
                <ul className="clist">
                    
                    <li className="citem">
                        <h3 className="cheading">Pokhara</h3>
                        <p>Address: Sabhagriha chowk,Pashupati plaza</p>
                        <p>Phone: 061-585362</p>
                        {/* <p>Email: ishahelpline@gmail.com</p> */}
                        
                        {/* <span className="cdate">Sub Branch</span> */}
                        <span className="ccircle"></span>
                    </li>
                    <li className="citem">
                        <h3 className="cheading">Chitwan</h3>
                        <p>Address: Sahid Chowk , Opposite of Kumari Bank</p>
                        <p>Phone: 9857082294</p>
                        <span className="ccircle"></span>
                    </li>
                </ul>
            </div>
        </div>
      </section>
    </>
  );
};
export default Contact;
