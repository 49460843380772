import React, { useState } from 'react';

const EnrollmentPage = () => {
  const [selectedCourse, setSelectedCourse] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: ''
  });
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://backend.ishahelpline.org/enrollment.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...formData, course: selectedCourse }),
      });

      const data = await response.json();
      if (data.status === 'success') {
        setPopupMessage(data.message);
        setShowPopup(true);
        // Reset form fields after submission if needed
        setFormData({
          name: '',
          email: '',
          phone: '',
          address: ''
        });
        setSelectedCourse('');
      } else {
        // Handle error
        alert(data.message);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Something went wrong. Please try again later.');
    }
  };

  return (
    <div className="container py-5 d-flex justify-content-center">
      <div className="card p-5" style={{ maxWidth: '600px', width: '100%' }}>
        <h2 className="mb-4">Enrollment Form</h2>
        <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
          <div className="mb-3">
            <input type="text" className="form-control" placeholder="Your Name" name="name" value={formData.name} onChange={handleChange} required />
          </div>
          <div className="mb-3">
            <input type="email" className="form-control" placeholder="Your Email" name="email" value={formData.email} onChange={handleChange} required />
          </div>
          <div className="mb-3">
            <input type="tel" className="form-control" placeholder="Your Phone" name="phone" value={formData.phone} onChange={handleChange} required />
          </div>
          <div className="mb-3">
            <select id="course" className="form-select" value={selectedCourse} onChange={(e) => setSelectedCourse(e.target.value)} required>
              <option>Select a course</option>
              <option value="Certificate I">Certificate I affiliated to CTEVT</option>
              <option value="Certificate II">Basic CNA (Certified Nursing Assistant) Certificate II</option>
              <option value="Certificate III">Certificate III in Community Service of Age Care</option>
              <option value="Certificate IV">Certificate IV in Individual Support for Age Care</option>
            </select>
          </div>
          <button type="submit" className="btn btn-primary btn-block">Submit</button>
        </form>
      </div>
      {showPopup && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-75">
          <div className="bg-white p-8 rounded shadow-md duration-300 hover:scale-105 hover:shadow-xl">
            <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto mt-8 h-16 w-16 text-green-400" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
            </svg>
            <h1 className="mt-2 text-center text-2xl font-bold text-gray-500">Success</h1>
            <p className="my-4 text-center text-sm text-gray-500">{popupMessage}</p>
            <div className="space-x-4 bg-gray-100 py-4 text-center">
              <button className="inline-block rounded-md bg-green-500 px-6 py-2 font-semibold text-green-100 shadow-md duration-75 hover:bg-green-400" onClick={() => setShowPopup(false)}>Okay</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EnrollmentPage;
