import React, { useEffect, useRef, useState } from "react";
import 'owl.carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import $ from 'jquery';


// const reviews = [
//   {
//     id: 1,
//     text: "Pagedone is simply the best tool of investment in the market right now.",
//     author: "Emily Johnson",
//     position: "CEO",
//     image: "https://pagedone.io/asset/uploads/1704349534.png"
// },
// {
//     id: 2,
//     text: "I was hesitant to try pagedone at first, but I'm so glad I did - it's exceeded all of my expectations.",
//     author: "Ethan Miller",
//     position: "Product Designer",
//     image: "https://pagedone.io/asset/uploads/1704349572.png"
// },
// {
//     id: 2,
//     text: "I was hesitant to try pagedone at first, but I'm so glad I did - it's exceeded all of my expectations.",
//     author: "Ethan Miller",
//     position: "Product Designer",
//     image: "https://pagedone.io/asset/uploads/1704349572.png"
// },
// {
//     id: 2,
//     text: "I was hesitant to try pagedone at first, but I'm so glad I did - it's exceeded all of my expectations.",
//     author: "Ethan Miller",
//     position: "Product Designer",
//     image: "https://pagedone.io/asset/uploads/1704349572.png"
// },
// {
//     id: 2,
//     text: "I was hesitant to try pagedone at first, but I'm so glad I did - it's exceeded all of my expectations.",
//     author: "Ethan Miller",
//     position: "Product Designer",
//     image: "https://pagedone.io/asset/uploads/1704349572.png"
// },
// ]
const About = () => {

  if (window.$) {
    const carousel = window.$(".team-carousel").owlCarousel({
        autoplay: true,
        smartSpeed: 1000,
        margin: 45,
        dots: false,
        loop: true,
        nav: true,
        navText: [
        ],
        responsive: {
            0: { items: 1 },
            992: { items: 2 }
        }
    });

    // Navigation buttons
    // window.$(".fa-angle-left").click(function() {
    //     carousel.trigger('prev.owl.carousel');
    // });

    // window.$(".fa-angle-right").click(function() {
    //     carousel.trigger('next.owl.carousel');
    // });
}

  const services = [
    { icon: 'fa-solid fa-star', heading: 'Exemplary Standards', description: 'Upholding unparalleled standards in caregiving education to ensure safety and excellence.' },
    { icon: 'fa-solid fa-hands-holding-child', heading: 'Professional Training', description: 'Offering specialized courses led by seasoned professionals, guaranteeing top-tier geriatric training.' },
    { icon: 'fa-regular fa-file-lines', heading: 'International Certification', description: `Providing certifications esteemed worldwide, solidifying our institution's reputation for excellence.`},
    { icon: 'fa-solid fa-users-between-lines', heading: 'Community Engagement', description: 'Engaging with communities on multiple fronts to foster awareness and support for elderly care initiatives.' },
    { icon: 'fa-solid fa-chalkboard-user', heading: 'Innovative Learning', description: 'Providing innovative and flexible learning options tailored to diverse learner needs and schedules.' },
    { icon: 'fa-solid fa-list-check', heading: 'Impactful Assessment', description: 'Implementing robust evaluation methods to ensure ongoing enhancement of our services and outcomes.' }
  ];

  const cards = [
    { title: 'Mission', image: '../assets/images/mission.png', description: 'Isha Helpline Caregiver Training and Research Center is committed to providing premier caregiver geriatric aged care training in Nepal. Led by our experienced Founder/CEO, Mrs. Indira Sharma, we equip individuals with essential skills through comprehensive programs from Basic CTEVT Certificate I to Supervisor Level IV.' },
    { title: 'Vision', image: '../assets/images/vision.jpg', description: 'Our vision is to lead the advancement of caregiver training in Nepal, ensuring every caregiver is well-prepared and compassionate. We aim to empower caregivers to make a meaningful impact on elderly care, fostering a society where seniors receive the respect and quality care they deserve.' },
    { title: 'Objective', image: '../assets/images/objective.jpg', description: `Our objectives include offering comprehensive training, maintaining quality standards, supporting professional development, engaging with the community, and measuring impact. We aim to provide rigorous training, uphold high standards, support ongoing learning, advocate for policies promoting elderly care, and continually assess our program's effectiveness. ` },
  ];

  const reviews = [
    {
      id: 1,
      content: "It is really the best caregiver center in Nepal. All the high quality tools and equipments provided by this institution makes it the best to ever exist. I am really thankful to the team of Isha for providing me the best skills and knowledge. I recommend Isha to everyone who is seeking for the best caregiver training center.",
      name: "Nidhi Sharma",
      rating: 5,
  },
  {
      id: 2,
      content: "I had the best experience with ISHA. They are professionals and I trust them because they have been working in this field for a long time now. I have friends who are willing to go abroad I’d definitely recommend them as well because I felt very confident in terms of moving and starting your new life in a new country, you really need some skills in hand. I thank ISHA for teaching me well and giving me that confidence to work in abroad. I wish my best.",
      name: "Nisha Sharma",
      rating: 5,
  },
  {
      id: 3,
      content: "Isha Age Care Training Centre exceeded my expectations. The programme was tailored by the skilled and caring instructors to meet the unique needs of providing care for the elderly. The practical experience provided an invaluable learning environment, guaranteeing graduates are fully prepared for scenarios in elder care. I wholeheartedly endorse it to anyone looking for a comprehensive and compassionate elder care education.",
      name: "Aashika Dahal",
      rating: 5,
  },
  {
      id: 4,
      content: "Isha helpline makes sure that you receive utmost level of care . Their team of professionals with high expertise and trainings don't compromise with the quality of service they can offer . I recommend visiting ISHA caregiver center , Putalisadak .",
      name: "ALISHA BUDHA",
      rating: 5,
  },
  {
      id: 4,
      content: "This experience is one of the best experience of my life. It is because this is our daily activities that we are learning as practical. Also, this is one of the best center to provide care training. 🥰 …",
      name: "Minto Pun",
      rating: 5,
  },
  {
      id: 4,
      content: "My sister went to Australia, this course has helped her a lot there, and Iam also being recommended by her, I am happy that I am doing a course here. Hope this helps me upon reaching Australia as well.",
      name: "Ramchandra Budhathoki",
      rating: 5,
  },
  {
      id: 4,
      content: "Isha Helpline Caregiver Training and Research Center is the best training center for who would like to have skill themselves.",
      name: "Smriti Karki",
      rating: 5,
  },
  {
      id: 4,
      content: "Well thinking about to learn some skills in the field of caregiver, i would definitely like to suggest you all to join Isha Helpline Caregiver Training Centre. Isha is one the renowned and experienced institute when it comes to providing both theoretical and practical knowledge in the caregiving field. The teaching faculty and methods are also quite updated which helps in the learning process.",
      name: "Pemba Sherpa",
      rating: 5,
  },
  {
      id: 4,
      content: "I went to many places before coming to Isha and did not like any besides here. I feel blessed and glad to have a training center like the Isha helpline which is also Australian-certified and affiliated with content. I am very happy with my training and all the trainers are highly qualified. The best environment to learn in all staff are so friendly and familiar.",
      name: "Suroj Kharel",
      rating: 5,
  },
  {
      id: 4,
      content: "Isha Helpline Caregiver Training and Research Center  is the best training center for who would like to have skill themselves. It's totally practical based skill training. Which is very useful for people. I had a great experience. Thank you very much for all🙏 …",
      name: "Bishnu Malla",
      rating: 5,
  },
  {
      id: 4,
      content: "It is really the best caregiver center in Nepal. All the high quality tools and equipments provided by this institution makes it the best to ever exist. I am really thankful to the team of Isha for providing me the best skills and knowledge. I recommend Isha to everyone who is seeking for the best caregiver training center.",
      name: "Yasha Thapa Magar",
      rating: 5,
  },
  {
      id: 4,
      content: "I went to many places before coming to Isha and did not like any besides here. I feel blessed and glad to have a training center like the Isha helpline which is also Australian-certified and affiliated with content. I am very happy with my training and all the trainers are highly qualified. The best environment to learn in all staff are so friendly and familiar.",
      name: "Suroj Kharel",
      rating: 5,
  },
  ]

  useEffect(() => {
    if (window.$) {
        const carousel = window.$(".review-carousel").owlCarousel({
            autoplay: true,
            smartSpeed: 1000,
            margin: 30, // Adjust margin as needed
            dots: false,
            loop: true,
            nav: true,
            // navText: [
            //     '<i class="fas fa-angle-left"></i>', 
            //     '<i class="fas fa-angle-right"></i>'
            // ],
            responsive: {
                0: { items: 1 },
                768: { items: 2 },
                1024: { items: 3 }
            }                
        });

        // Navigation buttons
        window.$(".fa-arrow-left").click(function() {
            carousel.trigger('prev.owl.carousel');
        });

        window.$(".fa-arrow-right").click(function() {
            carousel.trigger('next.owl.carousel');
        });
    }
}, []);

const renderStars = (rating) => {
  const starArray = [];
  for (let i = 0; i < rating; i++) {
      starArray.push(
          <svg key={i} className="w-5 h-5 text-yellow-500" viewBox="0 0 18 17" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.10326 1.31699C8.47008 0.57374 9.52992 0.57374 9.89674 1.31699L11.7063 4.98347C11.8519 5.27862 12.1335 5.48319 12.4592 5.53051L16.5054 6.11846C17.3256 6.23765 17.6531 7.24562 17.0596 7.82416L14.1318 10.6781C13.8961 10.9079 13.7885 11.2389 13.8442 11.5632L14.5353 15.5931C14.6754 16.41 13.818 17.033 13.0844 16.6473L9.46534 14.7446C9.17402 14.5915 8.82598 14.5915 8.53466 14.7446L4.91562 16.6473C4.18199 17.033 3.32456 16.41 3.46467 15.5931L4.15585 11.5632C4.21148 11.2389 4.10393 10.9079 3.86825 10.6781L0.940384 7.82416C0.346867 7.24562 0.674378 6.23765 1.4946 6.11846L5.54081 5.53051C5.86652 5.48319 6.14808 5.27862 6.29374 4.98347L8.10326 1.31699Z" />
          </svg>
      );
  }
  return starArray;
};

  
  return (
    <>

<section className="home-about">
  <div className="container-fluid py-5">
    <div className="container">
      <div className="row gx-5">
        <div className="col-lg-5 mb-5 mb-lg-0" style={{ minHeight: '500px' }}>
          <div className="position-relative h-100">
            <img className="position-absolute w-100 h-100 rounded" id="home-about-img" src="../assets/gallery/congratulations.png" style={{ objectFit: 'cover' }} alt=''/>
          </div>
        </div>
        <div className="col-lg-7">
          <div className="mb-4 home-about-text">
            <h5 className="d-inline-block text-primary text-uppercase border-bottom">About Us</h5>
            <h1 className="display-4">Premier Provider of Geriatric Caregiver Training</h1>
          </div>
          <p>Isha Helpline Caregiver Training and Research Center, the exclusive and only provider of caregiver geriatric aged care training from Basic CTEVT affiliated Certificate I to Supervisor Level IV in the context of Nepal as a distinguished private institution. Isha was officially registered in Nepal on 2019 A.D. despite the fact that the establishment was done 18 years ago internationally by our Founder/CEO Mrs. Indira Sharma. Mrs. Sharma has 20+ years of experience in this field deploying her expertise from Australia at Isha making the trained individual a professional at caregiving.</p>
          <div className="row g-3 pt-3">
            <div className="col-sm-3 col-6">
              <div className=" text-center  py-10 home-about-icon" style={{ backgroundImage: "url('../assets/elements/back.png')", backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                <i className="fa fa-3x  fa-ranking-star text-primary mb-3"></i>
                <h6 className="mb-0">No. 1 in<small className="d-block text-primary">Nepal</small></h6>
              </div>
            </div>
            <div className="col-sm-3 col-6">
              <div className=" text-center  py-10 home-about-icon" style={{ backgroundImage: "url('../assets/elements/back.png')", backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                <i className="fa-brands fa-3x fa-squarespace text-primary mb-3"></i>
                <h6 className="mb-0">CTEVT<small className="d-block text-primary">Affiliated</small></h6>
              </div>
            </div>
            <div className="col-sm-3 col-6">
              <div className=" text-center  py-10 home-about-icon" style={{ backgroundImage: "url('../assets/elements/back.png')", backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                <i className="fa fa-3x fa-file-invoice text-primary mb-3"></i>
                <h6 className="mb-0">International<small className="d-block text-primary">Certification</small></h6>
              </div>
            </div>
            <div className="col-sm-3 col-6">
              <div className=" text-center  py-10 home-about-icon" style={{ backgroundImage: "url('../assets/elements/back.png')", backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                <i className="fa fa-3x fa-face-grin-stars text-primary mb-3"></i>
                <h6 className="mb-0">Quality<small className="d-block text-primary">Assurance</small></h6>
              </div>
            </div>
            {/* Add similar divs for other icons */}
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

            {/* <section className="home-about">
      <div class="container-fluid py-5">
        <div class="container">
            <div class="row gx-5">
                <div class="col-lg-5 mb-5 mb-lg-0" style={{ minHeight: '500px' }}>
                    <div class="position-relative h-100">
                        <img class="position-absolute w-100 h-100 rounded" id='home-about-img' src="../assets/gallery/congratulations.png" style={{ objectFit: 'cover' }} alt=''/>
                    </div>
                </div>
                <div class="col-lg-7">
                    <div class="mb-4 home-about-text">
                        <h5 class="d-inline-block text-primary text-uppercase border-bottom  ">About Us</h5>
                        <h1 class="display-4">Premier Provider of Geriatric Caregiver Training</h1>
                    </div>
                    <p>Isha Helpline Caregiver Training and Research Center, the exclusive and only provider of caregiver geriatric aged care training from Basic CTEVT affiliated Certificate I to Supervisor Level IV  in the context of Nepal as a distinguished private institution. Isha was officially registered in Nepal on 2019 A.D. despite the fact that the establishment was done 18 years ago internationally by our Founder/CEO Mrs. Indira Sharma. Mrs. Sharma has 20+ years of experience in this field deploying her expertise from Australia at Isha making the trained individual a professional at caregiving.</p>
                    <div class="row g-3 pt-3">
                        <div class="col-sm-3 col-6">
                            <div class="bg-light text-center rounded-circle py-4 home-about-icon">
                                <i class="fa fa-3x fa-user-md text-primary mb-3"></i>
                                <h6 class="mb-0">Medical<small class="d-block text-primary">Oversight</small></h6>
                            </div>
                        </div>
                        <div class="col-sm-3 col-6">
                            <div class="bg-light text-center rounded-circle py-4 home-about-icon">
                                <i class="fa fa-3x fa-user-tie text-primary mb-3"></i>
                                <h6 class="mb-0">Professional<small class="d-block text-primary">Development</small></h6>
                            </div>
                        </div>
                        <div class="col-sm-3 col-6">
                            <div class="bg-light text-center rounded-circle py-4 home-about-icon">
                                <i class="fa fa-3x fa-street-view text-primary mb-3"></i>
                                <h6 class="mb-0">Personalized<small class="d-block text-primary">Support</small></h6>
                            </div>
                        </div>
                        <div class="col-sm-3 col-6">
                            <div class="bg-light text-center rounded-circle py-4 home-about-icon">
                                <i class="fa fa-3x fa-ranking-star text-primary mb-3"></i>
                                <h6 class="mb-0">Quality<small class="d-block text-primary">Assurance</small></h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
      </section> */}
      <section className="mvo">
      <div className="container">
        {cards.map((card, index) => (
          <div className="center" key={index}>
            <div className="front-face" style={{ backgroundImage: `url(${card.image})` }}>
              <div className="contents front">
                {/* <span>{card.title}</span> */}
              </div>
            </div>
            <div className="back-face">
              <div className="contents back">
                <h2 className="mt-2 max-sm:text-sm" style={{color: 'white'}}>{card.title}</h2>
                <p className=" max-sm:text-sm" style={{padding: '20px', textAlign: 'justify'}}>{card.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
      <section className="objectives">
        <h3>
          Why Choose <span>Isha?</span>
        </h3>
        <div className="objective-container">
          <div className="objective-image">
            <img src="../assets/images/expert.png" alt="Objective 1" />
          </div>
          <div className="objective-text ">
            <h2>Expert Instructors</h2>
            <p>
              Learn from industry experts with extensive experience. Our skilled
              instructors are committed to guiding you through every aspect of
              caregiver training, ensuring a comprehensive and insightful
              learning journey.
            </p>
          </div>
        </div>
        <div className="objective-container  objective-reversed">
          <div className="objective-text">
            <h2>Holistic Approach</h2>
            <p>
              Experience a well-rounded curriculum that combines theoretical
              knowledge with practical skills. Our holistic approach at Isha
              Helpline ensures you are equipped with the expertise needed to
              excel in the dynamic field of caregiving.
            </p>
          </div>
          <div className="objective-image">
            <img src="../assets/images/holistic.png" alt="Objective 1" />
          </div>
        </div>
        <div className="objective-container">
          <div className="objective-image">
            <img
              src="../assets/images/supportive_environment.png"
              alt="Objective 1"
            />
          </div>
          <div className="objective-text">
            <h2>Supportive Environment</h2>
            <p>
              Join a community that values your growth. At Isha Helpline, we
              foster a friendly and supportive environment, encouraging
              collaboration and interaction. Feel empowered to succeed with the
              encouragement of your peers and mentors.
            </p>
          </div>
        </div>
      </section>

      <section className="about-services">
      <div className="services-row">
      <div class="col-lg-7" style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', width: '100', margin: '40px auto 0 auto', }}>
                    <div class="mb-4 home-about-text">
                        <h5 class="d-inline-block text-primary text-uppercase border-bottom  ">Services</h5>
                        {/* <h1 class="display-4">Premier Provider of Geriatric Caregiver Training</h1> */}
                    </div>
                    </div>
      </div>
      <div className="services-row">
        {services.map((service, index) => (
          <div key={index} className="services-column">
            <div className="services-card">
              <div className="services-icon-wrapper">
                <i className={`${service.icon}`}></i>
              </div>
              <h3>{service.heading}</h3>
              <p>{service.description}</p>
            </div>
          </div>
        ))}
      </div>

    </section>


    <section class="py-12 relative">
        <div class="w-full max-w-7xl px-4 md:px-5 lg-6 mx-auto">
            <div class="w-full">
            <div className="mb-4 home-about-text mx-auto flex justify-center">
            <h5 className="d-inline-block text-primary text-uppercase border-bottom">Our Students Reviews</h5>
            {/* <h1 className="display-4">Premier Provider of Geriatric Caregiver Training</h1> */}
          </div>

                <div class="grid grid-cols-1 xl:grid-cols-2 gap-11 pb-11 border-b border-gray-100 max-xl:max-w-2xl max-xl:mx-auto">
                    <div class="box flex flex-col gap-y-4 w-full ">
                        <div class="flex items-center w-full">
                            <p class="font-medium text-lg text-[color:var(--primary-text)] mr-0.5">5</p>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_12042_8589)">
                                    <path
                                        d="M9.10326 2.31699C9.47008 1.57374 10.5299 1.57374 10.8967 2.31699L12.7063 5.98347C12.8519 6.27862 13.1335 6.48319 13.4592 6.53051L17.5054 7.11846C18.3256 7.23765 18.6531 8.24562 18.0596 8.82416L15.1318 11.6781C14.8961 11.9079 14.7885 12.2389 14.8442 12.5632L15.5353 16.5931C15.6754 17.41 14.818 18.033 14.0844 17.6473L10.4653 15.7446C10.174 15.5915 9.82598 15.5915 9.53466 15.7446L5.91562 17.6473C5.18199 18.033 4.32456 17.41 4.46467 16.5931L5.15585 12.5632C5.21148 12.2389 5.10393 11.9079 4.86825 11.6781L1.94038 8.82416C1.34687 8.24562 1.67438 7.23765 2.4946 7.11846L6.54081 6.53051C6.86652 6.48319 7.14808 6.27862 7.29374 5.98347L9.10326 2.31699Z"
                                        fill="#FBBF24" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_12042_8589">
                                        <rect width="20" height="20" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <p class="h-2 w-full sm:min-w-[278px] rounded-3xl bg-amber-50 ml-5 mr-3">
                                <span class="h-full w-[100%] rounded-3xl bg-amber-400 flex"></span>
                            </p>
                            <p class="font-medium text-lg  text-[color:var(--primary-text)] mr-0.5">120</p>
                        </div>
                        <div class="flex items-center w-full">
                            <p class="font-medium text-lg text-[color:var(--primary-text)] mr-0.5">4</p>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_12042_8589)">
                                    <path
                                        d="M9.10326 2.31699C9.47008 1.57374 10.5299 1.57374 10.8967 2.31699L12.7063 5.98347C12.8519 6.27862 13.1335 6.48319 13.4592 6.53051L17.5054 7.11846C18.3256 7.23765 18.6531 8.24562 18.0596 8.82416L15.1318 11.6781C14.8961 11.9079 14.7885 12.2389 14.8442 12.5632L15.5353 16.5931C15.6754 17.41 14.818 18.033 14.0844 17.6473L10.4653 15.7446C10.174 15.5915 9.82598 15.5915 9.53466 15.7446L5.91562 17.6473C5.18199 18.033 4.32456 17.41 4.46467 16.5931L5.15585 12.5632C5.21148 12.2389 5.10393 11.9079 4.86825 11.6781L1.94038 8.82416C1.34687 8.24562 1.67438 7.23765 2.4946 7.11846L6.54081 6.53051C6.86652 6.48319 7.14808 6.27862 7.29374 5.98347L9.10326 2.31699Z"
                                        fill="#FBBF24" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_12042_8589">
                                        <rect width="20" height="20" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <p class="h-2 w-full xl:min-w-[278px] rounded-3xl bg-amber-50 ml-5 mr-3">
                                <span class="h-full w-[10%] rounded-3xl bg-amber-400 flex"></span>
                            </p>
                            <p class="font-medium text-lg text-[color:var(--primary-text)] mr-0.5">15</p>
                        </div>
                        <div class="flex items-center">
                            <p class="font-medium text-lg text-[color:var(--primary-text)] mr-0.5">3</p>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_12042_8589)">
                                    <path
                                        d="M9.10326 2.31699C9.47008 1.57374 10.5299 1.57374 10.8967 2.31699L12.7063 5.98347C12.8519 6.27862 13.1335 6.48319 13.4592 6.53051L17.5054 7.11846C18.3256 7.23765 18.6531 8.24562 18.0596 8.82416L15.1318 11.6781C14.8961 11.9079 14.7885 12.2389 14.8442 12.5632L15.5353 16.5931C15.6754 17.41 14.818 18.033 14.0844 17.6473L10.4653 15.7446C10.174 15.5915 9.82598 15.5915 9.53466 15.7446L5.91562 17.6473C5.18199 18.033 4.32456 17.41 4.46467 16.5931L5.15585 12.5632C5.21148 12.2389 5.10393 11.9079 4.86825 11.6781L1.94038 8.82416C1.34687 8.24562 1.67438 7.23765 2.4946 7.11846L6.54081 6.53051C6.86652 6.48319 7.14808 6.27862 7.29374 5.98347L9.10326 2.31699Z"
                                        fill="#FBBF24" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_12042_8589">
                                        <rect width="20" height="20" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <p class="h-2 w-full xl:min-w-[278px] rounded-3xl bg-amber-50 ml-5 mr-3">
                                <span class="h-full w-[2%] rounded-3xl bg-amber-400 flex"></span>
                            </p>
                            <p class="font-medium text-lg text-[color:var(--primary-text)] mr-0.5">2</p>
                        </div>
                        <div class="flex items-center">
                            <p class="font-medium text-lg text-[color:var(--primary-text)] mr-0.5">2</p>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_12042_8589)">
                                    <path
                                        d="M9.10326 2.31699C9.47008 1.57374 10.5299 1.57374 10.8967 2.31699L12.7063 5.98347C12.8519 6.27862 13.1335 6.48319 13.4592 6.53051L17.5054 7.11846C18.3256 7.23765 18.6531 8.24562 18.0596 8.82416L15.1318 11.6781C14.8961 11.9079 14.7885 12.2389 14.8442 12.5632L15.5353 16.5931C15.6754 17.41 14.818 18.033 14.0844 17.6473L10.4653 15.7446C10.174 15.5915 9.82598 15.5915 9.53466 15.7446L5.91562 17.6473C5.18199 18.033 4.32456 17.41 4.46467 16.5931L5.15585 12.5632C5.21148 12.2389 5.10393 11.9079 4.86825 11.6781L1.94038 8.82416C1.34687 8.24562 1.67438 7.23765 2.4946 7.11846L6.54081 6.53051C6.86652 6.48319 7.14808 6.27862 7.29374 5.98347L9.10326 2.31699Z"
                                        fill="#FBBF24" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_12042_8589">
                                        <rect width="20" height="20" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <p class="h-2 w-full xl:min-w-[278px] rounded-3xl bg-amber-50 ml-5 mr-3">
                                <span class="h-full w-[0%] rounded-3xl bg-amber-400 flex"></span>
                            </p>
                            <p class="font-medium text-lg text-[color:var(--primary-text)] mr-0.5">0</p>
                        </div>
                        <div class="flex items-center">
                            <p class="font-medium text-lg text-[color:var(--primary-text)] mr-0.5">1</p>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_12042_8589)">
                                    <path
                                        d="M9.10326 2.31699C9.47008 1.57374 10.5299 1.57374 10.8967 2.31699L12.7063 5.98347C12.8519 6.27862 13.1335 6.48319 13.4592 6.53051L17.5054 7.11846C18.3256 7.23765 18.6531 8.24562 18.0596 8.82416L15.1318 11.6781C14.8961 11.9079 14.7885 12.2389 14.8442 12.5632L15.5353 16.5931C15.6754 17.41 14.818 18.033 14.0844 17.6473L10.4653 15.7446C10.174 15.5915 9.82598 15.5915 9.53466 15.7446L5.91562 17.6473C5.18199 18.033 4.32456 17.41 4.46467 16.5931L5.15585 12.5632C5.21148 12.2389 5.10393 11.9079 4.86825 11.6781L1.94038 8.82416C1.34687 8.24562 1.67438 7.23765 2.4946 7.11846L6.54081 6.53051C6.86652 6.48319 7.14808 6.27862 7.29374 5.98347L9.10326 2.31699Z"
                                        fill="#FBBF24" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_12042_8589">
                                        <rect width="20" height="20" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <p class="h-2 w-full xl:min-w-[278px] rounded-3xl bg-amber-50 ml-5 mr-3">
                                <span class="h-full w-[3.5%] rounded-3xl bg-amber-400 flex"></span>
                            </p>
                            <p class="font-medium text-lg py-[1px] text-[color:var(--primary-text)] mr-0.5">3</p>
                        </div>
                    </div>
                    <div class="p-8 bg-amber-50 rounded-3xl flex items-center justify-center flex-col">
                        <h2 class="font-manrope font-bold text-5xl text-amber-400 mb-6">
                            4.9</h2>
                        <div class="flex items-center justify-center gap-2 sm:gap-6 mb-4">
                            <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44"
                                fill="none">
                                <g clip-path="url(#clip0_13624_2608)">
                                    <path
                                        d="M21.1033 2.9166C21.4701 2.17335 22.5299 2.17335 22.8967 2.9166L28.233 13.729C28.3786 14.0241 28.6602 14.2287 28.9859 14.276L40.9181 16.0099C41.7383 16.1291 42.0658 17.137 41.4723 17.7156L32.8381 26.1318C32.6024 26.3616 32.4949 26.6926 32.5505 27.017L34.5888 38.9009C34.7289 39.7178 33.8714 40.3408 33.1378 39.9551L22.4653 34.3443C22.174 34.1911 21.826 34.1911 21.5347 34.3443L10.8622 39.9551C10.1286 40.3408 9.27114 39.7178 9.41125 38.9009L11.4495 27.017C11.5051 26.6926 11.3976 26.3616 11.1619 26.1318L2.52771 17.7156C1.93419 17.137 2.2617 16.1291 3.08192 16.0099L15.0141 14.276C15.3398 14.2287 15.6214 14.0241 15.767 13.729L21.1033 2.9166Z"
                                        fill="#FBBF24" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_13624_2608">
                                        <rect width="44" height="44" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44"
                                fill="none">
                                <g clip-path="url(#clip0_13624_2608)">
                                    <path
                                        d="M21.1033 2.9166C21.4701 2.17335 22.5299 2.17335 22.8967 2.9166L28.233 13.729C28.3786 14.0241 28.6602 14.2287 28.9859 14.276L40.9181 16.0099C41.7383 16.1291 42.0658 17.137 41.4723 17.7156L32.8381 26.1318C32.6024 26.3616 32.4949 26.6926 32.5505 27.017L34.5888 38.9009C34.7289 39.7178 33.8714 40.3408 33.1378 39.9551L22.4653 34.3443C22.174 34.1911 21.826 34.1911 21.5347 34.3443L10.8622 39.9551C10.1286 40.3408 9.27114 39.7178 9.41125 38.9009L11.4495 27.017C11.5051 26.6926 11.3976 26.3616 11.1619 26.1318L2.52771 17.7156C1.93419 17.137 2.2617 16.1291 3.08192 16.0099L15.0141 14.276C15.3398 14.2287 15.6214 14.0241 15.767 13.729L21.1033 2.9166Z"
                                        fill="#FBBF24" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_13624_2608">
                                        <rect width="44" height="44" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44"
                                fill="none">
                                <g clip-path="url(#clip0_13624_2608)">
                                    <path
                                        d="M21.1033 2.9166C21.4701 2.17335 22.5299 2.17335 22.8967 2.9166L28.233 13.729C28.3786 14.0241 28.6602 14.2287 28.9859 14.276L40.9181 16.0099C41.7383 16.1291 42.0658 17.137 41.4723 17.7156L32.8381 26.1318C32.6024 26.3616 32.4949 26.6926 32.5505 27.017L34.5888 38.9009C34.7289 39.7178 33.8714 40.3408 33.1378 39.9551L22.4653 34.3443C22.174 34.1911 21.826 34.1911 21.5347 34.3443L10.8622 39.9551C10.1286 40.3408 9.27114 39.7178 9.41125 38.9009L11.4495 27.017C11.5051 26.6926 11.3976 26.3616 11.1619 26.1318L2.52771 17.7156C1.93419 17.137 2.2617 16.1291 3.08192 16.0099L15.0141 14.276C15.3398 14.2287 15.6214 14.0241 15.767 13.729L21.1033 2.9166Z"
                                        fill="#FBBF24" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_13624_2608">
                                        <rect width="44" height="44" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44"
                                fill="none">
                                <g clip-path="url(#clip0_13624_2608)">
                                    <path
                                        d="M21.1033 2.9166C21.4701 2.17335 22.5299 2.17335 22.8967 2.9166L28.233 13.729C28.3786 14.0241 28.6602 14.2287 28.9859 14.276L40.9181 16.0099C41.7383 16.1291 42.0658 17.137 41.4723 17.7156L32.8381 26.1318C32.6024 26.3616 32.4949 26.6926 32.5505 27.017L34.5888 38.9009C34.7289 39.7178 33.8714 40.3408 33.1378 39.9551L22.4653 34.3443C22.174 34.1911 21.826 34.1911 21.5347 34.3443L10.8622 39.9551C10.1286 40.3408 9.27114 39.7178 9.41125 38.9009L11.4495 27.017C11.5051 26.6926 11.3976 26.3616 11.1619 26.1318L2.52771 17.7156C1.93419 17.137 2.2617 16.1291 3.08192 16.0099L15.0141 14.276C15.3398 14.2287 15.6214 14.0241 15.767 13.729L21.1033 2.9166Z"
                                        fill="#FBBF24" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_13624_2608">
                                        <rect width="44" height="44" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44"
                                fill="none">
                                <g clip-path="url(#clip0_13624_2608)">
                                    <path
                                        d="M21.1033 2.9166C21.4701 2.17335 22.5299 2.17335 22.8967 2.9166L28.233 13.729C28.3786 14.0241 28.6602 14.2287 28.9859 14.276L40.9181 16.0099C41.7383 16.1291 42.0658 17.137 41.4723 17.7156L32.8381 26.1318C32.6024 26.3616 32.4949 26.6926 32.5505 27.017L34.5888 38.9009C34.7289 39.7178 33.8714 40.3408 33.1378 39.9551L22.4653 34.3443C22.174 34.1911 21.826 34.1911 21.5347 34.3443L10.8622 39.9551C10.1286 40.3408 9.27114 39.7178 9.41125 38.9009L11.4495 27.017C11.5051 26.6926 11.3976 26.3616 11.1619 26.1318L2.52771 17.7156C1.93419 17.137 2.2617 16.1291 3.08192 16.0099L15.0141 14.276C15.3398 14.2287 15.6214 14.0241 15.767 13.729L21.1033 2.9166Z"
                                        fill="#FBBF24" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_13624_2608">
                                        <rect width="44" height="44" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <p class="font-medium text-xl leading-8 text-gray-900 text-center">140+ Ratings</p>
                    </div>
                </div>

            </div>
        </div>
    </section>
                                            
    <section className="py-4">
            {/* <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"> */}
            <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div class="mb-6 flex lg:flex-row flex-col items-center justify-between lg:gap-0 gap-4">
            <h2 class="text-md font-bold text-[color:var(--primary-text)] lg:text-left text-center">Some of Reviews </h2>
             {/* <!-- Slider controls --> */}
             <div class="flex items-center lg:justify-start justify-center gap-4">
                <button id="slider-button-left" class="slider-button group flex justify-center items-center border border-solid border-[color:var(--main)] w-12 h-12 transition-all duration-500 rounded-full hover:bg-[color:var(--main)] " data-carousel-prev>
                    
                        <i class="fas fa-arrow-left  text-[color:var(--main)] group-hover:text-white"></i>

                        
                </button>
                <button id="slider-button-right" class="slider-button group flex justify-center items-center border border-solid border-[color:var(--main)] w-12 h-12 transition-all duration-500 rounded-full hover:bg-[color:var(--main)]" data-carousel-next>
                    {/* <svg class="h-6 w-6 text-indigo-600 group-hover:text-white" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 12L19 12M14 18L19.2929 12.7071C19.6262 12.3738 19.7929 12.2071 19.7929 12C19.7929 11.7929 19.6262 11.6262 19.2929 11.2929L14 6" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg> */}
                         <i class="fas fa-arrow-right  text-[color:var(--main)] group-hover:text-white"></i>
                        
                </button>
            </div>
        </div>
                <div className="owl-carousel review-carousel">
                    {reviews.map((review, index) => (
                        <div key={index} className="group bg-white border border-solid border-gray-300 rounded-xl p-6 transition-all duration-500 mx-auto hover:border-indigo-600 hover:shadow-sm">
                            <div className="">
                                <div className="flex items-center mb-7 gap-2 text-amber-500 transition-all duration-500">
                                    {renderStars(review.rating)}
                                </div>
                                <p className="text-base text-gray-600 leading-6 transition-all duration-500 pb-8 group-hover:text-gray-800">
                                    {review.content}
                                </p>
                            </div>
                            <div className="flex items-center gap-5 border-t border-solid border-gray-200 pt-5">
                                {/* <img className="h-10 w-10" src={review.image} alt={review.name + ' image'} /> */}
                                <div className="block">
                                    <h5 className="text-gray-900 font-medium transition-all duration-500 mb-1">{review.name}</h5>
                                    {/* <span className="text-sm leading-4 text-gray-500">{review.position}</span> */}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {/* <div className="mt-16 flex space-x-3 justify-center">
                    {reviews.map((_, i) => (
                        <button key={i} type="button" className="w-4 h-1 rounded-full bg-gray-200 transition-all duration-500 hover:bg-indigo-600" aria-current="false" aria-label={`Slide ${i + 1}`} data-carousel-slide-to={i}></button>
                    ))}
                </div> */}
            </div>
        </section>
                                            
    </>
  );
};
export default About;
