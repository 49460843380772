import React from 'react';
import { Link, useLocation } from 'react-router-dom'; 

const CourseDetail2 = () => {
  return (
    <>  
    <div className="container py-5">
      <div className="row g-5">
        <div className="col-lg-8">
          {/* Course Detail Start */}
          <div className="mb-5">
            <img className="img-fluid w-100 rounded mb-5" src="../assets/gallery/gallery (1).JPG" alt="" />
            <h1 className="mb-4 courses-details-text" >Basic CNA (Certified Nursing Assistant) Certificate II</h1>
            <h3 className='courses-details-text'>About the Course:</h3>
            <p>Certified Nursing Assistant (CNA) Certificate II is advance care course valid internationally. Who is a CNA? The one who assists the nurse is a CNA. This course contains 36+ Basic CNA Chapters where Practical training is prioritized in the rate of 60%.  </p>
            <h3 className='courses-details-text'>Costs:</h3>
            <p>A$864</p>
            <h3 className='courses-details-text'>What You'll Learn:</h3>
            <ul className=' list-disc'>
  <li>Hand Hygiene</li>
  <li>Apply One Knee-High Elastic Stocking</li>
  <li>Assist to Ambulate Using Transfer Belt</li>
  <li>Assist with the Use of Bedpan</li>
  <li>Clean Upper or Lower Denture</li>
  <li>Count and Record Radial Pulse</li>
  <li>Count and Record Respirations</li>
  <li>Donning and Removing PPE (Gown and Gloves)</li>
  <li>Dress Client with Affected (weak) Right Arm</li>
  <li>Feed Client Who Cannot Feed Self</li>
  <li>Give Modified Bed Bath (Face and one arm, hand and underarm)</li>
  <li>Measure and Record Urinary Output</li>
  <li>Measure and Record Weight of Ambulatory Client</li>
  <li>Perform Modified Passive Range of Motion (PROM) for One Knee and One Ankle</li>
  <li>Perform Modified Passive Range of Motion (PROM) for One Shoulder</li>
  <li>Position on Side</li>
  <li>Provide Catheter Care for Male/Female</li>
  <li>Provide Mouth Care</li>
  <li>Provide Perineal Care (Peri-care) for female</li>
  <li>Transfer from Bed to Wheelchair Using Transfer Belt</li>
  <li>Measure and Record Manual Blood Pressure</li>
</ul>

            <h3 className='courses-details-text'>Prerequisites:</h3>
            <ul>
              <li>10+2 cleared in any field</li>
            </ul>
            {/* <button className="btn btn-primary btn-lg">Enroll Now</button> */}
          </div>
          {/* Course Detail End */}
        </div>
        {/* Sidebar Start */}
        <div className="col-lg-4">
          {/* About the Course Start */}
          <div className="mb-5">
            <h4 className="d-inline-block text-primary text-uppercase border-bottom   mb-4">About the Course</h4>
            <div className="bg-light rounded p-3">
              <h5>This Course Includes:</h5>
              <ul className="list-unstyled">
              <li><i className="fas fa-check-circle text-success me-2"></i>56 hours of Interactive Learning</li>
                <li><i className="fas fa-check-circle text-success me-2"></i>36+ grade-based chapters</li>
                <li><i className="fas fa-check-circle text-success me-2"></i>Theory classes</li>
                <li><i className="fas fa-check-circle text-success me-2"></i>Practical classes</li>
                <li><i className="fas fa-check-circle text-success me-2"></i>Demonstration</li>
                <li><i className="fas fa-check-circle text-success me-2"></i>Internship</li>
                <li><i className="fas fa-check-circle text-success me-2"></i>OJT preparation</li>
                <li><i className="fas fa-check-circle text-success me-2"></i>Test</li>
              </ul>
              <Link to='/enrollment'><button className="btn btn-primary btn-lg mt-3" id='enrollment-button'>Enroll Now</button></Link>
            </div>
          </div>
          {/* About the Course End */}
          {/* Top Courses Start */}
          <div className="mb-5">
            <h4 className="d-inline-block text-primary text-uppercase border-bottom   mb-4">Top Courses</h4>
            <div className="d-flex flex-column">
              <Link className="h5 bg-light rounded py-2 px-3 mb-2 details-link" to="/Course1">Certificate I affiliated to CTEVT</Link>
              <Link className="h5 bg-light rounded py-2 px-3 mb-2 details-link" to="/Course2">Basic CNA (Certified Nursing Assistant) Certificate II</Link>
              <Link className="h5 bg-light rounded py-2 px-3 mb-2 details-link" to="/Course3">Certificate III in Community Service</Link>
              <Link className="h5 bg-light rounded py-2 px-3 mb-2 details-link" to="/Course4">Certificate IV in Individual Support (Supervisor Level)</Link>
            </div>
          </div>
          {/* Top Courses End */}
          {/* Image Start */}
          <div className="mb-5">
            <img src="../assets/gallery/gallery (25).JPG" alt="" className="img-fluid rounded" />
          </div>
          {/* Image End */}
        </div>
        {/* Sidebar End */}
      </div>
    </div>
    {/* Course End */}
    
  {/* </div> */}
  </>
);
};

export default CourseDetail2;