import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom'; 
import $ from 'jquery';
import 'owl.carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const Home = () => {
  const [showMessage, setShowMessage] = useState(false);
  const [hoveredItem, setHoveredItem] = useState(null);
    useEffect(() => {
        if (window.$) {
            const carousel = window.$(".price-carousel").owlCarousel({
                // autoplay: true,
                smartSpeed: 1000,
                margin: 30, // Adjust margin as needed
                dots: false,
                loop: true,
                nav: true,
                navText: [
                    '<i class="fa-solid fa-angle-left"></i>',
                    '<i class="fa-solid fa-angle-right"></i>'
                ],
                responsive: {
                    0: { items: 1 },
                    576: { items: 1 },
                    992: { items: 2 },
                    1200: { items: 3 }
                }                
            });

            // Navigation buttons
            window.$(".fa-angle-left").click(function() {
                carousel.trigger('prev.owl.carousel');
            });

            window.$(".fa-angle-right").click(function() {
                carousel.trigger('next.owl.carousel');
            });
        }

        if (window.$) {
          const carousel = window.$(".team-carousel").owlCarousel({
              autoplay: true,
              smartSpeed: 1000,
              margin: 45,
              dots: false,
              loop: true,
              nav: true,
              navText: [
                  '<i class="fa-solid fa-angle-left"></i>',
                  '<i class="fa-solid fa-angle-right"></i>'
              ],
              responsive: {
                  0: { items: 1 },
                  992: { items: 2 }
              }
          });

          // Navigation buttons
          window.$(".fa-angle-left").click(function() {
              carousel.trigger('prev.owl.carousel');
          });

          window.$(".fa-angle-right").click(function() {
              carousel.trigger('next.owl.carousel');
          });

          
          
      }
    }, []);

    // Array of items for the carousel
    const course = [
        {
            title: "Certificate I affiliated to CTEVT",
            image: "../assets/images/course_2.jpg",
            link: '/Course1',
            features: [
                "Completion hours: 390",
                "6 grade-based skills chapters✅",
                // "NRP 30,000",
                // "Theory classes",
                // "Practical classes",
                // "Internship",
                // "OJT preparation",
                // "Test✅",
            ],
            tprice: "",
            dprice:  "NPR 30,000",
        },
        {
            title: "Basic CNA (Certified Nursing Assistant) Certificate II",
            image: "../assets/images/course_1.jpg",
            link: '/Course2',
            features: [
                "Completion hours: 56",
                "36+ grade-based chapters✅",
                // "NRP 75,000",
                // "Theory classes",
                // "Practical classes",
                // "Demonstration",
                // "Internship",
                // "OJT preparation",
                // "Test✅",
            ],
            tprice: "",
            dprice:  "A$864",
        },
        {
            title: "Certificate III in Community Service",
            image: "../assets/images/course_3.jpg",
            link: '/Course3',
            features: [
                "Completion hours: 390",
                "Cognitive Behavior Therapy (CBT)",
                // "NRP 1,25,000",
                // "Psychological & Physical Well-being",
                // "65+ geriatric grade-based chapters",
                // "Theory classes",
                // "Practical classes",
                // "Demonstration with foreign equipment",
                // "Internship",
                // "OJT preparation",
                // "Test✅",
            ],
            tprice: "A$8300",
            dprice:  "A$1439",
        },
        {
            title: "Certificate IV in Individual Support (Supervisor Level)",
            image: "../assets/images/course_4.jpg",
            link: '/Course4',
            features: [
                "Duration: 26 weeks",
                "Supervisor Level Training",
                // "NRP 1,70,000",
                // "Desk work & Independence",
                // "21+ Individual Support Techniques✅",
                // "Demonstration with foreign equipment",
            ],
            tprice: "A$8900",
            dprice:  "A$1957",
        },
        
    ];
    

  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 200,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const videoRef = useRef(null);

  const [isPlaying, setIsPlaying] = useState(false); // Track the playing state

  useEffect(() => {
    const handlePlay = () => {
      if (!isPlaying) {
        videoRef.current.play();
        setIsPlaying(true);
      }
    };

    const handlePause = () => {
      if (isPlaying) {
        videoRef.current.pause();
        setIsPlaying(false);
      }
    };

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            handlePlay();
          } else {
            handlePause();
          }
        });
      },
      { threshold: 0.5 } // Adjust threshold as needed
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, [isPlaying]);

  
  const today = new Date().toISOString().split('T')[0];
  const [selectedDate, setSelectedDate] = useState('');
  // const [isDateSelected, setIsDateSelected] = useState(false);

  // const handleDateChange = (e) => {
  //   const selectedDateValue = e.target.value;
  //   setSelectedDate(selectedDateValue);
  //   setIsDateSelected(true);
  // };

  const teamMembers = [
    {
      name: 'Indira Sharma',
      position: 'CEO/Founder',
      imageSrc: '../assets/images/indra.jpg',
    },
    {
      name: 'Briyanka Adhikari',
      position: 'Co-Founder/ Managing Director',
      imageSrc: '../assets/images/briyanka.jpg',
    },
    {
      name: 'Tilak Sharma',
      position: 'Management Executive',
      imageSrc: '../assets/images/tilak.jpg',
    },
    {
      name: 'Priyanshu Adhikari',
      position: 'Senior Management & Executive',
      imageSrc: '../assets/images/priyanshu.jpg',
    },
    {
      name: 'Nitesh Khadka',
      position: 'International Advisor (Melbourne, Australia)',
      imageSrc: '../assets/images/nitesh.jpg',
    },
    {
      name: 'Kanchan Karki',
      position: 'Senior Facilitator',
      imageSrc: '../assets/images/kanchan.jpg',
    },
    // {
    //   name: 'Gd Sikhar',
    //   position: 'Butwal Branch Manager',
    //   imageSrc: '../assets/images/sikhar.jpg',
    // },
  ];



  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDateSelected, setIsDateSelected] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');
  const [submitStatus, setSubmitStatus] = useState('');

  const handleDateChange = (e) => {
    setIsDateSelected(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitMessage('');
    setSubmitStatus('');

    const formData = new FormData(e.target);

    try {
      const response = await fetch('https://backend.ishahelpline.org/appointment.php', {
        method: 'POST',
        body: formData,
      });

      const result = await response.json();

      if (result.status === 'success') {
        setSubmitMessage('Appointment booked successfully.');
        setSubmitStatus('success');
      } else {
        setSubmitMessage('Failed to book appointment. Please try again.');
        setSubmitStatus('error');
      }
    } catch (error) {
      setSubmitMessage('An error occurred. Please try again.');
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <>
      {/* <section className="home">
        <div className="home-content">
          <h2 >"A Legacy of Excellence"</h2>
          <p>
            Welcome to Isha Helpline, where your journey to excellence in
            caregiver training begins. Our institute is dedicated to providing a
            transformative learning experience, blending theoretical knowledge
            with practical skills. Discover a nurturing environment, experienced
            instructors, and a commitment to shaping compassionate and skilled
            caregivers. Join us on your path to professional success in the
            rewarding field of caregiving.
          </p>
          <button>
            <a href="/">Contact Us</a>
          </button>
        </div>
        <div className="home-cubes">
          <img src="../assets/images/home.png" alt="blocks" />
        </div>
      </section> */}
      <div className='home_gif'>

      <video ref={videoRef} src="../assets/home.mp4" className='w-[100%]' autoPlay muted playsInline loop></video>  
      </div>

      {/* <section className="home-courses">
      <div className="container-fluid py-5">
        <div className="container">
          <div className="text-center mx-auto mb-5" style={{ maxWidth: "500px" }}>
            <h5 className="d-inline-block text-primary text-uppercase border-bottom">Our Courses</h5>
          </div>
          <div className="owl-carousel price-carousel position-relative " style={{ padding: "0 45px 45px 45px" }}>
            {items.map((item, index) => (
              <div 
                key={index} 
                className="bg-light rounded text-center relative"
                onMouseEnter={() => setHoveredItem(index)}
                onMouseLeave={() => setHoveredItem(null)}
              >
                <div className="relative">
                  <img className="img-fluid rounded-top" src={item.image} alt="" />
                  <div className="absolute w-100 h-100 top-0 start-0 rounded-top d-flex flex-column align-items-center justify-content-center hover-box" style={{ background: "rgba(29, 42, 77, .8)" }}>
                    <h3 className="text-white">{item.title}</h3>
                    <p className='uppercase text-lg text-[color:#ffb703] font-bold'>certificate</p>
                    <h1 className="display-4 text-white mb-0">
                    </h1>
                  </div>
                </div>
                <div className="text-center py-5">
                  {item.features.map((feature, cont) => (
                    <p key={cont}>{feature}</p>
                  ))}
                  <div className="relative">
                    <p>
                      <span
                        className='line-through mr-2 hover:text-blue-500 cursor-pointer'
                        onMouseEnter={() => setShowMessage(true)}
                        onMouseLeave={() => setShowMessage(false)}
                      >
                        {item.tprice}
                      </span>
                      {item.dprice}
                    </p>
                    {showMessage && hoveredItem === index && (
                      <div
                        className="absolute left-1/2 transform -translate-x-1/2 bottom-full bg-gray-900 bg-opacity-75 p-3 rounded-lg shadow-lg text-white"
                        onMouseEnter={() => setShowMessage(true)}
                        onMouseLeave={() => setShowMessage(false)}
                      >
                        <p className='text-xs'>Isha delivers courses at a low cost to ensure accessibility for all, fostering inclusivity and community. This aligns with their social mission, reaching a wider audience and promoting long-term sustainability. By removing financial barriers, they aim to share their teachings with anyone seeking spiritual growth, prioritizing impact over profit.</p>
                      </div>
                    )}
                  </div>
                  <Link to={item.link} className="btn btn-primary rounded-pill py-3 px-5 my-2 courses-button">View Course</Link>
                </div>
              </div>
            ))}
          </div>
          <div className="courses-button-angle">
            <i className="fa-solid fa-angle-left"></i>
            <i className="fa-solid fa-angle-right"></i>
          </div>
        </div>
      </div>
    </section> */}

<section className='w-[75%] max-xl:w-[85%] max-md:w-[95%] mx-auto my-20'>
    <div className="text-center mx-auto mb-5" style={{ maxWidth: "500px" }}>
            <h5 className="d-inline-block font-bold text-4xl text-primary text-uppercase border-bottom">Our Top Courses</h5>
          </div>
      <div className='flex flex-wrap gap-4'>
    {course.map((course,index) =>(
      <div class=" bg-white border border-gray-200 rounded-lg basis-[30%] max-lg:basis-[48%] max-sm:basis-[98%]" key={index}>
          <Link to={course.link}>
              <img class="rounded-t-lg" src={course.image} alt="" />
          </Link>
          <div class="p-4">
              <Link to={course.link}>
                  <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 line-clamp-2 h-16">{course.title}</h5>
              </Link>
              {course.features.map((feature, cont) => (
                    <li key={cont}>{feature}</li>
                  ))}
                
                <p className='uppercase  text-xl my-2 text-[color:#ffb703] font-bold'>certificate</p>
              <Link to={course.link} class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                  Read more
                  <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                  </svg>
              </Link>
          </div>
      </div>
    ))}

      </div>
    </section>

    <section className="appointment">
      <div className="container-fluid bg-primary my-5 py-5" id="appointment-container">
        <div className="container py-5">
          <div className="row gx-5">
            <div className="col-lg-6 mb-5 mb-lg-0">
              <div className="mb-4">
                <h5 className="d-inline-block text-white text-uppercase border-bottom">Appointment</h5>
                <h1 className="display-4">Make An Appointment</h1>
              </div>
              <p className="text-white mb-5">
                Schedule your consultation today to embark on your journey towards exceptional caregiving. Our team is ready to assist you in selecting the perfect training program tailored to your needs and aspirations.
              </p>
              <a className="btn btn-dark rounded-pill py-3 px-5 me-3" href="/enquiry">Contact Us</a>
            </div>
            <div className="col-lg-6">
              <div className="bg-white text-center rounded p-5">
                <h1 className="mb-4">Book An Appointment</h1>
                <form onSubmit={handleSubmit}>
                  <div className="row g-3">
                    <div className="col-12 col-sm-6">
                      <input type="text" name="name" className="form-control bg-light border-0" placeholder="Your Name" style={{ height: '55px' }} required />
                    </div>
                    <div className="col-12 col-sm-6">
                      <input type="email" name="email" className="form-control bg-light border-0" placeholder="Your Email" style={{ height: '55px' }} required />
                    </div>
                    <div className="col-12 col-sm-6">
                      <select name="country" className="form-select bg-light border-0" style={{ height: '55px' }} required>
                        <option>Choose Country</option>
                        <option value="Aus">Australia</option>
                        <option value="UK">UK</option>
                        <option value="USA">USA</option>
                        <option value="Israel">Israel</option>
                        <option value="Germany">Germany</option>
                        <option value="Others">Others</option>
                      </select>
                    </div>
                    <div className="col-12 col-sm-6">
                      <select name="counselor" className="form-select bg-light border-0" style={{ height: '55px' }} required>
                        <option selected>Select Counselor</option>
                        <option value="Indira Sharma">Indira Sharma</option>
                        <option value="Priyanshu Adhikari">Priyanshu Adhikari</option>
                        <option value="Tilak Sharma">Tilak Sharma</option>
                      </select>
                    </div>
                    <div className="col-12 col-sm-6">
                      <input type="date" name="date" className="form-control bg-light border-0 mt-0" min={new Date().toISOString().split('T')[0]} onChange={handleDateChange} style={{ height: '55px' }} required />
                    </div>
                    <div className="col-12 col-sm-6">
                      <select name="time" className={`form-select border-0 ${isDateSelected ? 'bg-light' : 'bg-gray-800'} shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm`} style={{ height: '55px' }} required disabled={!isDateSelected}>
                        <option disabled={!isDateSelected} value="">Choose Time</option>
                        <option value="11AM">11:00 AM Available</option>
                        <option value="12PM">12:00 PM Available</option>
                        <option value="1PM">1:00 PM Available</option>
                        <option value="3PM">3:00 PM Available</option>
                        <option value="4PM">4:00 PM Available</option>
                      </select>
                    </div>
                    <div className="col-12">
                      <button className="btn btn-primary w-100 py-3" type="submit" id="appointment-button">
                        {isSubmitting ? 'Booking Appointment...' : 'Make An Appointment'}
                      </button>
                    </div>
                  </div>
                </form>
                {submitMessage && (
                  <div className={`alert ${submitStatus === 'success' ? 'alert-success' : 'alert-danger'} mt-3`} role="alert">
                    {submitMessage}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


      {/* <section className="appointment">
    <div class="container-fluid bg-primary my-5 py-5" id='appointment-container'>
        <div class="container py-5">
            <div class="row gx-5">
                <div class="col-lg-6 mb-5 mb-lg-0">
                    <div class="mb-4">
                        <h5 class="d-inline-block text-white text-uppercase border-bottom  ">Appointment</h5>
                        <h1 class="display-4">Make An Appointment</h1>
                    </div>
                    <p class="text-white mb-5">Schedule your consultation today to embark on your journey towards exceptional caregiving. Our team is ready to assist you in selecting the perfect training program tailored to your needs and aspirations.</p>
                    <a class="btn btn-dark rounded-pill py-3 px-5 me-3" href="/enquiry">Contact Us</a>
                </div>
                <div class="col-lg-6">
                    <div class="bg-white text-center rounded p-5">
                        <h1 class="mb-4">Book An Appointment</h1>
                        <form>
                            <div class="row g-3">
                            <div class="col-12 col-sm-6">
                                    <input type="text" class="form-control bg-light border-0" placeholder="Your Name" style={{height: '55px'}} />
                                </div>
                                <div class="col-12 col-sm-6">
                                    <input type="email" class="form-control bg-light border-0" placeholder="Your Email" style={{height: '55px'}} />
                                </div>
                                <div class="col-12 col-sm-6">
                                    <select class="form-select bg-light border-0" style={{height: '55px'}}>
                                    <option >Choose Country</option>
                                    <option value="Aus">Australia</option>
                                    <option value="UK">UK</option>
                                    <option value="USA">USA</option>
                                    <option value="Isreal">Isreal</option>
                                    <option value="Germany">Germany</option>
                                    <option value="Others">Others</option>
                                    </select>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <select class="form-select bg-light border-0" style={{height: '55px'}}>
                                        <option selected>Select Councellor</option>
                                        <option value="1">Indira Sharma</option>
                                        <option value="2">Priyanshu Adhikari</option>
                                        <option value="3">Tilak Sharma</option>
                                    </select>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <div class="date" id="date" data-target-input="nearest" style={{height: '55px'}}>
                                        <input type='date'
                                            class="form-control bg-light border-0 datetimepicker-input mt-0  "
                                            placeholder="Date" data-target="#date" min={today} data-toggle="datetimepicker" onChange={handleDateChange} style={{height: '55px'}} 
                                            />
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <select style={{height: '55px'}}className={`form-select  border-0 ${
          isDateSelected ? ' bg-light ' : 'bg-gray-800 '
        } shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm`}
        disabled={!isDateSelected}>
                                    <option disabled={!isDateSelected}>Choose Time</option>
                                    <option value="11AM">11:00 AM Available</option>
                                    <option value="12AM">12:00 AM Available</option>
                                    <option value="1PM">1:00 PM Available</option>
                                    <option value="3PM">3:00 PM Available</option>
                                    <option value="4PM">4:00 PM Available</option>
                                    </select>
                                </div>
                                <div class="col-12">
                                    <button class="btn btn-primary w-100 py-3" type="submit" id='appointment-button'>Make An Appointment</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
      </section> */}

      <section className="home-about">
  <div className="container-fluid py-5">
    <div className="container">
      <div className="row gx-5">
        <div className="col-lg-5 mb-5 mb-lg-0" style={{ minHeight: '500px' }}>
          <div className="position-relative h-100">
            <img className="position-absolute w-100 h-100 rounded" id="home-about-img" src="../assets/gallery/congratulations.png" style={{ objectFit: 'cover' }} alt=''/>
          </div>
        </div>
        <div className="col-lg-7">
          <div className="mb-4 home-about-text">
            <h5 className="d-inline-block text-primary text-uppercase border-bottom">About Us</h5>
            <h1 className="display-4">Premier Provider of Geriatric Caregiver Training</h1>
          </div>
          <p>Isha Helpline Caregiver Training and Research Center, the exclusive and only provider of caregiver geriatric aged care training from Basic CTEVT affiliated Certificate I to Supervisor Level IV in the context of Nepal as a distinguished private institution. Isha was officially registered in Nepal on 2019 A.D. despite the fact that the establishment was done 18 years ago internationally by our Founder/CEO Mrs. Indira Sharma. Mrs. Sharma has 20+ years of experience in this field deploying her expertise from Australia at Isha making the trained individual a professional at caregiving.</p>
          <div className="row g-3 pt-3">
            <div className="col-sm-3 col-6">
              <div className=" text-center  py-10 home-about-icon" style={{ backgroundImage: "url('../assets/elements/back.png')", backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                <i className="fa fa-3x  fa-ranking-star text-primary mb-3"></i>
                <h6 className="mb-0">No. 1 in<small className="d-block text-primary">Nepal</small></h6>
              </div>
            </div>
            <div className="col-sm-3 col-6">
              <div className=" text-center  py-10 home-about-icon" style={{ backgroundImage: "url('../assets/elements/back.png')", backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                <i className="fa-brands fa-3x fa-squarespace text-primary mb-3"></i>
                <h6 className="mb-0">CTEVT<small className="d-block text-primary">Affiliated</small></h6>
              </div>
            </div>
            <div className="col-sm-3 col-6">
              <div className=" text-center  py-10 home-about-icon" style={{ backgroundImage: "url('../assets/elements/back.png')", backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                <i className="fa fa-3x fa-file-invoice text-primary mb-3"></i>
                <h6 className="mb-0">International<small className="d-block text-primary">Certification</small></h6>
              </div>
            </div>
            <div className="col-sm-3 col-6">
              <div className=" text-center  py-10 home-about-icon" style={{ backgroundImage: "url('../assets/elements/back.png')", backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                <i className="fa fa-3x fa-face-grin-stars text-primary mb-3"></i>
                <h6 className="mb-0">Quality<small className="d-block text-primary">Assurance</small></h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>



      <section className="home-help">
        <h2>
          HOW WE CAN <span>HELP</span> YOU
        </h2>
        <div className="home-help-box">
          <div className="help-box box1">
            <img src="../assets/icons/home-help-01.svg" alt="Career" />
            <h3>Comprehensive Curriculum</h3>
            <p>
              Benefit from a comprehensive curriculum that covers both
              theoretical foundations and hands-on practical training. Isha
              Helpline ensures you gain a deep understanding of caregiving
              principles and real-world application.
            </p>
          </div>
          <div className="help-box box2">
            <img src="../assets/icons/home-help-02.svg" alt="World" />
            <h3>Personalized Guidance</h3>
            <p>
              Receive personalized guidance from our experienced instructors who
              are dedicated to your success. Whether you are a beginner or
              seeking advanced skills, our team at Isha Helpline provides
              tailored support to meet your individual learning needs.
            </p>
          </div>
          <div className="help-box box3">
            <img src="../assets/icons/home-help-03.svg" alt="Bridging" />
            <h3>Career Placement Assistance</h3>
            <p>
              Navigate the path to your caregiving career with confidence. Isha
              Helpline goes beyond education by offering career placement
              assistance, connecting graduates with meaningful opportunities in
              both local and international settings.
            </p>
          </div>
          <div className="help-box box4">
            <img src="../assets/icons/home-help-04.svg" alt="Service" />
            <h3>State-of-the-Art Facilities</h3>
            <p>
              Train in a conducive environment equipped with state-of-the-art
              facilities. Isha Helpline provides modern classrooms and practical
              training spaces, ensuring a comfortable and effective learning
              experience for all students.
            </p>
          </div>
          <div className="help-box box5">
            <img src="../assets/icons/home-help-05.svg" alt="assessment" />
            <h3>Continuous Learning Resources</h3>
            <p>
              Access a wealth of continuous learning resources. Isha Helpline
              supports your ongoing professional development with additional
              resources, workshops, and industry insights to keep your skills
              up-to-date and relevant.
            </p>
          </div>
          <div className="help-box box6">
            <img src="../assets/icons/home-help-06.svg" alt="collaboration" />
            <h3>Community and Networking</h3>
            <p>
              Become part of a thriving community of caregivers. Isha Helpline
              encourages networking and collaboration among students, alumni,
              and industry professionals, creating opportunities for mentorship,
              shared experiences, and lifelong connections.
            </p>
          </div>
        </div>
      </section>



      {/* <section className="home-team">
        <div className="container-fluid py-5">
            <div className="container">
                <div className="text-center mx-auto mb-5" style={{ maxWidth: "500px" }}>
                    <h5 className="d-inline-block text-primary text-uppercase border-bottom  ">Our Teams</h5>
                </div>
                <div className="owl-carousel team-carousel position-relative">
                    {Teams.map((team, index) => (
                        <div key={index} className="team-item">
                            <div className="row g-0 bg-light rounded overflow-hidden">
                                <div className="col-12 col-sm-5 h-100">
                                    <img className="img-fluid h-100" src={team.image} style={{ objectFit: "cover" }} alt={team.name} />
                                </div>
                                <div className="col-12 col-sm-7 h-100 d-flex flex-column">
                                    <div className="mt-auto p-4">
                                        <h3>{team.name}</h3>
                                        <h6 className="fw-normal fst-italic text-primary mb-4">{team.specialty}</h6>
                                        <p className="m-0">{team.description}</p>
                                    </div>
                                    <div className="d-flex mt-auto border-top p-4">
                                        <a className="btn btn-lg btn-primary btn-lg-square rounded-circle me-3" href="#"><i className="fab fa-instagram"></i></a>
                                        <a className="btn btn-lg btn-primary btn-lg-square rounded-circle me-3" href="#"><i className="fab fa-facebook-f"></i></a>
                                        <a className="btn btn-lg btn-primary btn-lg-square rounded-circle" href="#"><i className="fab fa-linkedin-in"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
      </section> */}


      {/* <section>
      <div class="container my-24 mx-auto md:px-6">

  <section class="mb-32 text-center">

    <div class="grid gap-x-6 md:grid-cols-3 lg:gap-x-12">
      <div class="">
        <div
          class="block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
          <div class="relative overflow-hidden bg-cover bg-no-repeat">
            <img src="https://mdbcdn.b-cdn.net/img/new/avatars/15.jpg" class="w-full rounded-t-lg" />
            <a href="#!">
              <div class="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-fixed"></div>
            </a>
            <svg class="absolute text-white dark:text-neutral-700 left-0 bottom-0" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1440 320">
              <path fill="currentColor"
                d="M0,288L48,256C96,224,192,160,288,160C384,160,480,224,576,213.3C672,203,768,117,864,85.3C960,53,1056,75,1152,69.3C1248,64,1344,32,1392,16L1440,0L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z">
              </path>
            </svg>
          </div>
          <div class="p-6">
            <h5 class="mb-4 text-lg font-bold">Ayat Black</h5>
            <p class="mb-4 text-neutral-500 dark:text-neutral-300">Web designer</p>
            
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
      </section> */}


      <section>
      <div className="px-4 pb-4 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="text-center mx-auto" style={{ maxWidth: "500px" }}>
                    <h5 className="d-inline-block text-primary text-uppercase border-bottom  ">Meet Our Team</h5>
                </div>
    </div>
      </section>

      <div className="w-[90%] overflow-hidden mx-auto">
<div className="space-y-4">
      <Slider
  ref={slider => {
    sliderRef = slider;
  }}
  {...settings}
>
    {teamMembers.map((member, index) => (
      <div key={index} className="flex flex-col items-center space-y-4 shadow-md">
        <img
          alt={member.name}
          className=" w-80 h-80 object-cover mx-auto mb-4 rounded-sm"
          src={member.imageSrc}
        />
        <div className="flex flex-col items-center">
          <h4 className="text-xl font-semibold text-primary">{member.name}</h4>
          <p className="text-sm text-[color:var(--tertiary-text)]">{member.position}</p>
        </div>
      </div>
    ))}
</Slider>
    </div>
      <div className="flex justify-center mt-1" >
        <i className="fa-solid fa-arrow-left bg-[color:var(--main)] text-[color:white] rounded-full p-2 text-[12px] m-3 cursor-pointer" onClick={previous}>
          
        </i>
        <i className="fa-solid fa-arrow-right bg-[color:var(--main)] text-[color:white] rounded-full p-2 text-[12px] m-3 cursor-pointer" onClick={next}>
          
        </i>
      </div>
    </div>    

    <section className="home-blog">
  <div className="container-fluid py-5">
    <div className="container">
      <div className="text-center mx-auto mb-5" style={{ maxWidth: '500px' }}>
        <h5 className="d-inline-block text-primary text-uppercase border-bottom">Blog Post</h5>
        <h1 className="display-4">Our Latest Blog</h1>
      </div>
      <div className="row g-5">
        {[
          {
            title: 'Patient lifter Hoist',
            description: 'A patient lifter is a piece of medical equipment designed to assist caregivers in safely transferring patients who have limited mobility or are unable to move independently.',
            imageSrc: '../assets/images/hoist.png',
            link: '/blog1',
          },
          {
            title: 'Nurturing Well-being',
            description: "Isha Helpline Caregiver Training & Research Center's Journey with Cognitive Behavior Therapy (CBT) in Elder Care",
            imageSrc: '../assets/gallery/gallery (99).JPG',
            link: '/blog2',
          },
          {
            title: 'Internships in Age Care Homes',
            description: 'Trainees of Isha share their experiences and the issues that they faced during their time in the aged care homes.',
            imageSrc: '../assets/gallery/gallery (12).JPG',
            link: '/blog3',
          },
        ].map((blog, index) => (
          <div key={index} className="col-xl-4 col-lg-6">
            <div className="bg-light rounded overflow-hidden">
              <img
                className="w-full !h-96  object-cover"
                src={blog.imageSrc}
                alt={blog.title}
                style={{ width: '100%', height: '200px', objectFit: 'cover' }}
              />
              <div className="p-4">
                <Link className="h3 d-block mb-3" to={blog.link}>{blog.title}</Link>
                <p className="m-0 line-clamp-2">{blog.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
</section>


      {/* <section className="mvo">
      <div class="col-lg-7" style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', width: '100', margin: '40px auto 0 auto', }}>
                    <div class="mb-4 home-about-text">
                        <h5 class="d-inline-block text-primary text-uppercase border-bottom  ">Our GAllery</h5>
                    </div>
                    </div>
    <div className="carousel">

    <div className="carousel-img">
    <div className="carousel-content">
      <div className="carousel-items">
        <img src="../../assets/images/img1.jpg" alt="pic" />
      </div>
      <div className="carousel-items">
        <img src="../../assets/images/img2.jpg" alt="pic" />
      </div>
      <div className="carousel-items">
        <img src="../../assets/images/img3.jpg" alt="pic" />
      </div>
      <div className="carousel-items">
        <img src="../../assets/images/img4.jpg" alt="pic" />
      </div>
      <div className="carousel-items">
        <img src="../../assets/images/img5.jpg" alt="pic" />
      </div>
      <div className="carousel-items">
        <img src="../../assets/images/img6.jpg" alt="pic" />
      </div>
    </div>
  </div>
    </div>
    </section> */}


      
    </>
  );
};
export default Home;
