import React from 'react';

const Extra = () => {
  const cards = [
    { title: 'Mission', image: '../assets/images/mission.png', description: 'Isha Helpline Caregiver Training and Research Center is committed to providing premier caregiver geriatric aged care training in Nepal. Led by our experienced Founder/CEO, Mrs. Indira Sharma, we equip individuals with essential skills through comprehensive programs from Basic CTEVT Certificate I to Supervisor Level IV.' },
    { title: 'Vision', image: '../assets/images/vision.jpg', description: 'Our vision is to lead the advancement of caregiver training in Nepal, ensuring every caregiver is well-prepared and compassionate. We aim to empower caregivers to make a meaningful impact on elderly care, fostering a society where seniors receive the respect and quality care they deserve.' },
    { title: 'Objective', image: '../assets/images/objective.jpg', description: `Our objectives include offering comprehensive training, maintaining quality standards, supporting professional development, engaging with the community, and measuring impact. We aim to provide rigorous training, uphold high standards, support ongoing learning, advocate for policies promoting elderly care, and continually assess our program's effectiveness. ` },
  ];

  return (
    <section className="mvo">
      <div className="container">
        {cards.map((card, index) => (
          <div className="center" key={index}>
            <div className="front-face" style={{ backgroundImage: `url(${card.image})` }}>
              <div className="contents front">
                {/* <span>{card.title}</span> */}
              </div>
            </div>
            <div className="back-face">
              <div className="contents back">
                <h2 style={{color: 'white'}}>{card.title}</h2>
                <p style={{padding: '20px', textAlign: 'justify'}}>{card.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Extra;
