import React from 'react';
import { Link, useLocation } from 'react-router-dom'; 

const CourseDetail1 = () => {
  return (
    <>  
    <div className="container py-5">
      <div className="row g-5">
        <div className="col-lg-8">
          {/* Course Detail Start */}
          <div className="mb-5">
            <img className="img-fluid w-100 rounded mb-5" src="../assets/gallery/gallery (17).JPG" alt="" />
            <h1 className="mb-4 courses-details-text" >Certificate I affiliated to CTEVT</h1>
            <h3 className='courses-details-text'>About the Course:</h3>
            <p>Certificate I is a basic caregiver level course affiliated to Nepal Government CTEVT. </p>
            <h3 className='courses-details-text'>Costs:</h3>
            <p>NPR 30,000</p>
            <h3 className='courses-details-text'>What You'll Learn:</h3>
            <ul className=' list-disc'>
              <li>Environment Health and Sanitation </li>
              <li>Nutrition</li>
              <li>Emergency and First Aid</li>
              <li>Hygiene Care</li>
              <li>Maternal Care</li>
              <li>Communication & Counseling</li>
            </ul>
            <h3 className='courses-details-text'>Prerequisites:</h3>
            <ul>
              <li>10+2 cleared in any field</li>
            </ul>
            {/* <button className="btn btn-primary btn-lg">Enroll Now</button> */}
          </div>
          {/* Course Detail End */}
        </div>
        {/* Sidebar Start */}
        <div className="col-lg-4">
          {/* About the Course Start */}
          <div className="mb-5">
            <h4 className="d-inline-block text-primary text-uppercase border-bottom   mb-4">About the Course</h4>
            <div className="bg-light rounded p-3">
              <h5>This Course Includes:</h5>
              <ul className="list-unstyled">
                <li><i className="fas fa-check-circle text-success me-2"></i>390 hours of Interactive Learning</li>
                <li><i className="fas fa-check-circle text-success me-2"></i>6 grade-based skills chapters</li>
                <li><i className="fas fa-check-circle text-success me-2"></i>Theory classes</li>
                <li><i className="fas fa-check-circle text-success me-2"></i>Practical classes</li>
                <li><i className="fas fa-check-circle text-success me-2"></i>Internship</li>
                <li><i className="fas fa-check-circle text-success me-2"></i>OJT preparation</li>
                <li><i className="fas fa-check-circle text-success me-2"></i>Test</li>
              </ul>
              <Link to='/enrollment'><button className="btn btn-primary btn-lg mt-3" id='enrollment-button'>Enroll Now</button></Link>
            </div>
          </div>
          {/* About the Course End */}
          {/* Top Courses Start */}
          <div className="mb-5">
            <h4 className="d-inline-block text-primary text-uppercase border-bottom   mb-4">Top Courses</h4>
            <div className="d-flex flex-column">
              <Link className="h5 bg-light rounded py-2 px-3 mb-2 details-link" to="/Course1">Certificate I affiliated to CTEVT</Link>
              <Link className="h5 bg-light rounded py-2 px-3 mb-2 details-link" to="/Course2">Basic CNA (Certified Nursing Assistant) Certificate II</Link>
              <Link className="h5 bg-light rounded py-2 px-3 mb-2 details-link" to="/Course3">Certificate III in Community Service</Link>
              <Link className="h5 bg-light rounded py-2 px-3 mb-2 details-link" to="/Course4">Certificate IV in Individual Support (Supervisor Level)</Link>
            </div>
          </div>
          {/* Top Courses End */}
          {/* Image Start */}
          <div className="mb-5">
            <img src="../assets/gallery/gallery (20).JPG" alt="" className="img-fluid rounded" />
          </div>
          {/* Image End */}
        </div>
        {/* Sidebar End */}
      </div>
    </div>
    {/* Course End */}
    
  {/* </div> */}
  </>
);
};

export default CourseDetail1;