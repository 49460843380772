import React from 'react';
import { Link, useLocation } from 'react-router-dom'; 

const CourseDetail3 = () => {
  return (
    <>  
    <div className="container py-5">
      <div className="row g-5">
        <div className="col-lg-8">
          {/* Course Detail Start */}
          <div className="mb-5">
            <img className="img-fluid w-100 rounded mb-5" src="../assets/gallery/gallery (7).JPG" alt="" />
            <h1 className="mb-4 courses-details-text" >Certificate III in Community Service of Age Care</h1>
            <h3 className='courses-details-text'>About the Course:</h3>
            <p>Certificate III in Community Service of Age Care is basic age care international course valid internationally. Who takes this course? The one who wants to work in community services such as Age care homes, Nursing Homes, etc. This course contains 65+ Age Care Geriatric Study: Practical & Theory training is prioritized equally. Since this course include various studies regarding Geriatric Learning, Understanding & Tackling ways</p>
            <h3 className='courses-details-text'>Costs:</h3>
            <p>A$<span className='line-through'>8300</span> A$1439</p>
            <h3 className='courses-details-text'>What You'll Learn:</h3>
            <ul className=' list-disc'>
  <li><i>Orientation of Aged Care Work</i></li>
  <li><i>Advocate clients from Morning 7AM to Afternoon 3:30 PM</i></li>
  <li><i>Starting of Duty</i></li>
  <li><i>Communicate with clients</i></li>
  <li><i>Occupational Health and Safety Policies</i></li>
  <li><i>Assist with Aged People’s personal needs</i></li>
  <li><i>Work with specific communities</i></li>
  <li><i>Process and Provide information</i></li>
  <li><i>Provide administration support</i></li>
  <li><i>Participate in work environment</i></li>
  <li><i>Deliver Services to the client</i></li>
  <li><i>Provide first aid to the client</i></li>
  <li><i>Provide Normal diet</i></li>
  <li><i>Provide Whole Body Care</i></li>
  <li><i>Clean Bathroom and Bedroom</i></li>
  <li><i>Perform the last office</i></li>
  <li><i>Cognitive Behavioral Therapy</i></li>
  <li><i>Disability Care</i></li>
  <li><i>Post-mortem care/ body preparation</i></li>
  <li><i>Assistance to prevent harms</i></li>
</ul>


            <h3 className='courses-details-text'>Prerequisites:</h3>
            <ul>
              <li>10+2 cleared in any field</li>
            </ul>
            {/* <button className="btn btn-primary btn-lg">Enroll Now</button> */}
          </div>
          {/* Course Detail End */}
        </div>
        {/* Sidebar Start */}
        <div className="col-lg-4">
          {/* About the Course Start */}
          <div className="mb-5">
            <h4 className="d-inline-block text-primary text-uppercase border-bottom   mb-4">About the Course</h4>
            <div className="bg-light rounded p-3">
              <h5>This Course Includes:</h5>
              <ul className="list-unstyled">
              <li><i className="fas fa-check-circle text-success me-2"></i>56 hours of Interactive Learning</li>
              <li><i className="fas fa-check-circle text-success me-2"></i>Cognitive Behavior Therapy (CBT)</li>
              <li><i className="fas fa-check-circle text-success me-2"></i>Psychological & Physical Well-being</li>
              <li><i className="fas fa-check-circle text-success me-2"></i>65+ geriatric grade-based chapters</li>
                <li><i className="fas fa-check-circle text-success me-2"></i>36+ grade-based chapters</li>
                <li><i className="fas fa-check-circle text-success me-2"></i>Theory classes</li>
                <li><i className="fas fa-check-circle text-success me-2"></i>Practical classes</li>
                <li><i className="fas fa-check-circle text-success me-2"></i>Demonstration with foreign equipment</li>
                <li><i className="fas fa-check-circle text-success me-2"></i>Internship</li>
                <li><i className="fas fa-check-circle text-success me-2"></i>OJT preparation</li>
                <li><i className="fas fa-check-circle text-success me-2"></i>Test</li>
              </ul>
              <Link to='/enrollment'><button className="btn btn-primary btn-lg mt-3" id='enrollment-button'>Enroll Now</button></Link>
            </div>
          </div>
          {/* About the Course End */}
          {/* Top Courses Start */}
          <div className="mb-5">
            <h4 className="d-inline-block text-primary text-uppercase border-bottom   mb-4">Top Courses</h4>
            <div className="d-flex flex-column">
              <Link className="h5 bg-light rounded py-2 px-3 mb-2 details-link" to="/Course1">Certificate I affiliated to CTEVT</Link>
              <Link className="h5 bg-light rounded py-2 px-3 mb-2 details-link" to="/Course2">Basic CNA (Certified Nursing Assistant) Certificate II</Link>
              <Link className="h5 bg-light rounded py-2 px-3 mb-2 details-link" to="/Course3">Certificate III in Community Service</Link>
              <Link className="h5 bg-light rounded py-2 px-3 mb-2 details-link" to="/Course4">Certificate IV in Individual Support (Supervisor Level)</Link>
            </div>
          </div>
          {/* Top Courses End */}
          {/* Image Start */}
          <div className="mb-5">
            <img src="../assets/gallery/gallery (4).JPG" alt="" className="img-fluid rounded" />
          </div>
          {/* Image End */}
        </div>
        {/* Sidebar End */}
      </div>
    </div>
    {/* Course End */}
    
  {/* </div> */}
  </>
);
};

export default CourseDetail3;