import React, { useState } from 'react';

function Appointment() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDateSelected, setIsDateSelected] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');
  const [submitStatus, setSubmitStatus] = useState('');

  const handleDateChange = (e) => {
    setIsDateSelected(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitMessage('');
    setSubmitStatus('');

    const formData = new FormData(e.target);

    try {
      const response = await fetch('https://backend.ishahelpline.org/appointment.php', {
        method: 'POST',
        body: formData,
      });

      const result = await response.json();

      if (result.status === 'success') {
        setSubmitMessage('Appointment booked successfully.');
        setSubmitStatus('success');
      } else {
        setSubmitMessage('Failed to book appointment. Please try again.');
        setSubmitStatus('error');
      }
    } catch (error) {
      setSubmitMessage('An error occurred. Please try again.');
      setSubmitStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section className="appointment">
      <div className="container-fluid bg-primary my-5 py-5" id="appointment-container">
        <div className="container py-5">
          <div className="row gx-5">
            <div className="col-lg-6 mb-5 mb-lg-0">
              <div className="mb-4">
                <h5 className="d-inline-block text-white text-uppercase border-bottom">Appointment</h5>
                <h1 className="display-4">Make An Appointment</h1>
              </div>
              <p className="text-white mb-5">
                Schedule your consultation today to embark on your journey towards exceptional caregiving. Our team is ready to assist you in selecting the perfect training program tailored to your needs and aspirations.
              </p>
              <a className="btn btn-dark rounded-pill py-3 px-5 me-3" href="/enquiry">Contact Us</a>
            </div>
            <div className="col-lg-6">
              <div className="bg-white text-center rounded p-5">
                <h1 className="mb-4">Book An Appointment</h1>
                <form onSubmit={handleSubmit}>
                  <div className="row g-3">
                    <div className="col-12 col-sm-6">
                      <input type="text" name="name" className="form-control bg-light border-0" placeholder="Your Name" style={{ height: '55px' }} required />
                    </div>
                    <div className="col-12 col-sm-6">
                      <input type="email" name="email" className="form-control bg-light border-0" placeholder="Your Email" style={{ height: '55px' }} required />
                    </div>
                    <div className="col-12 col-sm-6">
                      <select name="country" className="form-select bg-light border-0" style={{ height: '55px' }} required>
                        <option>Choose Country</option>
                        <option value="Aus">Australia</option>
                        <option value="UK">UK</option>
                        <option value="USA">USA</option>
                        <option value="Israel">Israel</option>
                        <option value="Germany">Germany</option>
                        <option value="Others">Others</option>
                      </select>
                    </div>
                    <div className="col-12 col-sm-6">
                      <select name="counselor" className="form-select bg-light border-0" style={{ height: '55px' }} required>
                        <option selected>Select Counselor</option>
                        <option value="Indira Sharma">Indira Sharma</option>
                        <option value="Priyanshu Adhikari">Priyanshu Adhikari</option>
                        <option value="Tilak Sharma">Tilak Sharma</option>
                      </select>
                    </div>
                    <div className="col-12 col-sm-6">
                      <input type="date" name="date" className="form-control bg-light border-0 mt-0" min={new Date().toISOString().split('T')[0]} onChange={handleDateChange} style={{ height: '55px' }} required />
                    </div>
                    <div className="col-12 col-sm-6">
                      <select name="time" className={`form-select border-0 ${isDateSelected ? 'bg-light' : 'bg-gray-800'} shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm`} style={{ height: '55px' }} required disabled={!isDateSelected}>
                        <option disabled={!isDateSelected} value="">Choose Time</option>
                        <option value="11AM">11:00 AM Available</option>
                        <option value="12PM">12:00 PM Available</option>
                        <option value="1PM">1:00 PM Available</option>
                        <option value="3PM">3:00 PM Available</option>
                        <option value="4PM">4:00 PM Available</option>
                      </select>
                    </div>
                    <div className="col-12">
                      <button className="btn btn-primary w-100 py-3" type="submit" id="appointment-button">
                        {isSubmitting ? 'Booking Appointment...' : 'Make An Appointment'}
                      </button>
                    </div>
                  </div>
                </form>
                {submitMessage && (
                  <div className={`alert ${submitStatus === 'success' ? 'alert-success' : 'alert-danger'} mt-3`} role="alert">
                    {submitMessage}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Appointment;
