import React from 'react';
import { Link, useLocation } from 'react-router-dom'; 

const CourseDetail3 = () => {
  return (
    <>  
    <div className="container py-5">
      <div className="row g-5">
        <div className="col-lg-8">
          {/* Course Detail Start */}
          <div className="mb-5">
            <img className="img-fluid w-100 rounded mb-5" src="../assets/gallery/congratulations.png" alt="" />
            <h1 className="mb-4 courses-details-text" >Certificate IV in Individual Support for Age Care</h1>
            <h3 className='courses-details-text'>About the Course:</h3>
            <p>Certificate IV in Individual Support opens a gateway to superior working freedom supporting the residence to empower internal independence. This course contains 21+ Individual Support Techniques and Leadership opportunities. </p>
            <h3 className='courses-details-text'>Costs:</h3>
            <p>A$<span className='line-through'>8900</span> A$1957</p>
            <h3 className='courses-details-text'>What You'll Learn:</h3>
            <ul className=' list-disc'>
  <li><i>Facilitate the interests and rights of clients</i></li>
  <li><i>Facilitate the empowerment of older people</i></li>
  <li><i>Coordinate services for older people</i></li>
  <li><i>Implement interventions with older people at risk</i></li>
  <li><i>Provide support to people living with dementia</i></li>
  <li><i>Facilitate individual service planning and delivery</i></li>
  <li><i>Meet personal support needs</i></li>
  <li><i>Support independence and well-being</i></li>
  <li><i>Support relationships with carers and families</i></li>
  <li><i>Work with diverse people</i></li>
  <li><i>Manage legal and ethical compliance</i></li>
  <li><i>Deliver care services using a palliative approach</i></li>
  <li><i>Develop and maintain networks and collaborative partnerships</i></li>
  <li><i>Recognize healthy body systems</i></li>
  <li><i>Follow safe work practices for direct client care</i></li>
  <li><i>Use communication to build relationships</i></li>
  <li><i>Provide loss and grief support</i></li>
  <li><i>Follow basic food safety practices</i></li>
  <li><i>Implement falls prevention strategies</i></li>
  <li><i>Respond to suspected abuse</i></li>
  <li><i>Provide first aid</i></li>
</ul>



            <h3 className='courses-details-text'>Prerequisites:</h3>
            <ul>
              <li>10+2 cleared in any field</li>
            </ul>
            {/* <button className="btn btn-primary btn-lg">Enroll Now</button> */}
          </div>
          {/* Course Detail End */}
        </div>
        {/* Sidebar Start */}
        <div className="col-lg-4">
          {/* About the Course Start */}
          <div className="mb-5">
            <h4 className="d-inline-block text-primary text-uppercase border-bottom   mb-4">About the Course</h4>
            <div className="bg-light rounded p-3">
              <h5>This Course Includes:</h5>
              <ul className="list-unstyled">
              <li><i className="fas fa-check-circle text-success me-2"></i>26 weeks of Interactive Learning</li>
              <li><i className="fas fa-check-circle text-success me-2"></i>Supervisor Level Training</li>
              <li><i className="fas fa-check-circle text-success me-2"></i>Desk work & Independence </li>
              <li><i className="fas fa-check-circle text-success me-2"></i>21+ Individual Support Techniques</li>
                {/* <li><i className="fas fa-check-circle text-success me-2"></i>36+ grade-based chapters</li> */}
                <li><i className="fas fa-check-circle text-success me-2"></i>Theory classes</li>
                <li><i className="fas fa-check-circle text-success me-2"></i>Practical classes</li>
                <li><i className="fas fa-check-circle text-success me-2"></i>Demonstration with foreign equipment</li>
                <li><i className="fas fa-check-circle text-success me-2"></i>Internship</li>
                <li><i className="fas fa-check-circle text-success me-2"></i>OJT preparation</li>
                <li><i className="fas fa-check-circle text-success me-2"></i>Test</li>
              </ul>
              <Link to='/enrollment'><button className="btn btn-primary btn-lg mt-3" id='enrollment-button'>Enroll Now</button></Link>
            </div>
          </div>
          {/* About the Course End */}
          {/* Top Courses Start */}
          <div className="mb-5">
            <h4 className="d-inline-block text-primary text-uppercase border-bottom   mb-4">Top Courses</h4>
            <div className="d-flex flex-column">
              <Link className="h5 bg-light rounded py-2 px-3 mb-2 details-link" to="/Course1">Certificate I affiliated to CTEVT</Link>
              <Link className="h5 bg-light rounded py-2 px-3 mb-2 details-link" to="/Course2">Basic CNA (Certified Nursing Assistant) Certificate II</Link>
              <Link className="h5 bg-light rounded py-2 px-3 mb-2 details-link" to="/Course3">Certificate III in Community Service</Link>
              <Link className="h5 bg-light rounded py-2 px-3 mb-2 details-link" to="/Course4">Certificate IV in Individual Support (Supervisor Level)</Link>
            </div>
          </div>
          {/* Top Courses End */}
          {/* Image Start */}
          <div className="mb-5">
            <img src="../assets/gallery/gallery (4).JPG" alt="" className="img-fluid rounded" />
          </div>
          {/* Image End */}
        </div>
        {/* Sidebar End */}
      </div>
    </div>
    {/* Course End */}
    
  {/* </div> */}
  </>
);
};

export default CourseDetail3;