import React from 'react';
import { Link, useLocation } from 'react-router-dom'; 

const BlogDetail2 = () => {
  return (
    <>
  
    <div className="container py-5">
      <div className="row g-5">
        <div className="col-lg-8">
          {/* Blog Detail Start */}
          <div className="mb-5">
            <img className="img-fluid w-100 rounded mb-5" src="../assets/gallery/gallery (99).JPG" alt="" />
            <h1 className="mb-4" id='blog-text-color'>Nurturing Well-being: </h1>
            <p>Isha Helpline Caregiver Training & Research Center's Journey with Cognitive Behavior Therapy (CBT) in Elder Care</p>
            <p>In the realm of elder care, where compassion meets complexity, the significance of innovative approaches cannot be overstated. In this pursuit, Isha Helpline Caregiver Training & Research Center has emerged as a beacon of excellence since its inception in 2019. Through a blend of cutting-edge research and compassionate caregiving practices, Isha is revolutionizing the landscape of elderly care, ensuring a holistic and enriching experience for both caregivers and seniors.</p>
            <p>Central to Isha's mission is the integration of Cognitive Behavior Therapy (CBT) into age care practices. Since its establishment, the center has tirelessly pursued research to understand the intricacies of caregiving, with a particular focus on addressing the psychological and emotional challenges faced by caregivers. Through the lens of CBT, Isha provides caregivers with tailored strategies to navigate stress, burnout, and compassion fatigue effectively. These evidence-based interventions empower caregivers, fostering resilience and enhancing their overall well-being.</p>
            <p>However, Isha's commitment to enhancing elderly care transcends the realm of research and training. The center recognizes the importance of recreational activities in promoting social engagement and cognitive stimulation among seniors. To this end, Isha organizes various games and activities such as pass the parcel, chess, ludo, and carem board. These activities not only encourage social interaction but also contribute to mental agility and enjoyment among elderly residents.</p>
            <p>One of the hallmarks of Isha's initiatives is its commitment to transparency and accessibility. Recognizing the importance of disseminating knowledge and resources, Isha ensures that its training materials and recreational activities are readily available to a wide audience. Clips showcasing Isha's training programs, research findings, and recreational activities are accessible on YouTube, providing valuable insights and guidance to caregivers and elder care professionals worldwide.</p>
            <p>Moreover, Isha leverages the power of social media platforms to amplify its impact and reach. Through active engagement on platforms such as Facebook, Twitter, Instagram, and LinkedIn, Isha shares informative content, updates on research initiatives, and inspiring stories from the caregiving community. By fostering a supportive online community, Isha encourages collaboration, knowledge-sharing, and continuous learning in the field of elderly care.</p>
            <p>In addition to its digital presence, Isha Helpline Caregiver Training & Research Center also hosts regular workshops, seminars, and conferences, providing a platform for caregivers, healthcare professionals, and researchers to exchange ideas and insights. These events facilitate networking opportunities and foster a culture of innovation and excellence in elder care.</p>
            <p>Furthermore, Isha is committed to ensuring inclusivity and diversity in its programs and initiatives. The center collaborates with local communities, organizations, and institutions to tailor its services to meet the specific needs and cultural preferences of diverse populations. By embracing cultural diversity and sensitivity, Isha strives to create an inclusive and welcoming environment for all individuals involved in elder care.</p>
            <p>As we look towards the future, the work of Isha Helpline Caregiver Training & Research Center serves as a testament to the transformative power of compassion, innovation, and collaboration in elder care. By integrating CBT principles, promoting recreational activities, and leveraging digital platforms, Isha is paving the way for a brighter and more compassionate future for caregivers and seniors alike. Through its unwavering dedication and pioneering spirit, Isha continues to redefine the standards of excellence in elderly care, inspiring hope and resilience in communities around the world.</p>


            {/* <div className="d-flex justify-content-between bg-light rounded p-4 mt-4 mb-4">
              <div className="d-flex align-items-center">
                <img className="rounded-circle me-2" src="../assets/images/user.jpg" width="40" height="40" alt="" />
                <span>John Doe</span>
              </div>
              <div className="d-flex align-items-center">
                <span className="ms-3"><i className="far fa-eye text-primary me-1"></i>12345</span>
                <span className="ms-3"><i className="far fa-comment text-primary me-1"></i>123</span>
              </div>
            </div> */}
          </div>
          {/* Blog Detail End */}
          {/* Comment List Start */}
          {/* <div className="mb-5">
            <h4 className="d-inline-block text-primary text-uppercase border-bottom   mb-4">3 Comments</h4>
            <div className="d-flex mb-4">
              <img src="../assets/images/user.jpg" className="img-fluid rounded-circle" style={{ width: '45px', height: '45px' }} alt="" />
              <div className="ps-3">
                <h6><a href="">John Doe</a> <small><i>01 Jan 2045</i></small></h6>
                <p>Diam amet duo labore stet elitr invidunt ea clita ipsum voluptua, tempor labore
                  accusam ipsum et no at. Kasd diam tempor rebum magna dolores sed eirmod</p>
                <button className="btn btn-sm btn-light">Reply</button>
              </div>
            </div>
            <div className="d-flex mb-4">
              <img src="../assets/images/user.jpg" className="img-fluid rounded-circle" style={{ width: '45px', height: '45px' }} alt="" />
              <div className="ps-3">
                <h6><a href="">John Doe</a> <small><i>01 Jan 2045</i></small></h6>
                <p>Diam amet duo labore stet elitr invidunt ea clita ipsum voluptua, tempor labore
                  accusam ipsum et no at. Kasd diam tempor rebum magna dolores sed eirmod</p>
                <button className="btn btn-sm btn-light">Reply</button>
              </div>
            </div>
            <div className="d-flex ms-5 mb-4">
              <img src="../assets/images/user.jpg" className="img-fluid rounded-circle" style={{ width: '45px', height: '45px' }} alt="" />
              <div className="ps-3">
                <h6><a href="">John Doe</a> <small><i>01 Jan 2045</i></small></h6>
                <p>Diam amet duo labore stet elitr invidunt ea clita ipsum voluptua, tempor labore
                  accusam ipsum et no at. Kasd diam tempor rebum magna dolores sed eirmod</p>
                <button className="btn btn-sm btn-light">Reply</button>
              </div>
            </div>
          </div> */}
          {/* Comment List End */}
          {/* Comment Form Start */}
          {/* <div className="bg-light rounded p-5">
            <h4 className="d-inline-block text-primary text-uppercase border-bottom   border-white mb-4">Leave a comment</h4>
            <form>
              <div className="row g-3">
                <div className="col-12 col-sm-6">
                  <input type="text" className="form-control bg-white border-0" placeholder="Your Name" style={{ height: '55px' }} />
                </div>
                <div className="col-12 col-sm-6">
                  <input type="email" className="form-control bg-white border-0" placeholder="Your Email" style={{ height: '55px' }} />
                </div>
                <div className="col-12">
                  <input type="text" className="form-control bg-white border-0" placeholder="Website" style={{ height: '55px' }} />
                </div>
                <div className="col-12">
                  <textarea className="form-control bg-white border-0" rows="5" placeholder="Comment"></textarea>
                </div>
                <div className="col-12">
                  <button className="btn btn-primary w-100 py-3" id='comment-button' type="submit">Leave Your Comment</button>
                </div>
              </div>
            </form>
          </div> */}
          {/* Comment Form End */}
        </div>
        {/* Sidebar Start */}
        <div className="col-lg-4">
          {/* Search Form Start */}
          {/* <div className="mb-5">
            <div className="input-group">
              <input type="text" className="form-control p-3" placeholder="Keyword" />
              <button className="btn btn-primary px-3"><i className="fa fa-search"></i></button>
            </div>
          </div> */}
          {/* Search Form End */}
          {/* Category Start */}
          <div className="mb-5">
            <h4 className="d-inline-block text-primary text-uppercase border-bottom   mb-4">Top Courses</h4>
            <div className="d-flex flex-column justify-content-start blog-courses">
              <Link to={'/course1'} className="h5 bg-light rounded py-2 px-3 mb-2" >Certificate I affiliated to CTEVT <i className="fa fa-angle-right me-2"></i></Link>
              <Link to={'/course2'} className="h5 bg-light rounded py-2 px-3 mb-2" >Basic CNA (Certified Nursing Assistant) Certificate II<i className="fa fa-angle-right me-2"></i></Link>
              <Link to={'/course3'} className="h5 bg-light rounded py-2 px-3 mb-2" >Certificate III in Community Service of Age Care<i className="fa fa-angle-right me-2"></i></Link>
              <Link to={'/course4'} className="h5 bg-light rounded py-2 px-3 mb-2" >Certificate IV in Individual Support for Age Care<i className="fa fa-angle-right me-2"></i></Link>
            </div>
          </div>
          {/* Category End */}
          {/* Recent Post Start */}
          <div className="mb-5 blog-courses">
            <h4 className="d-inline-block text-primary text-uppercase border-bottom   mb-4">Recent Post</h4>
            <div className="d-flex rounded overflow-hidden mb-3 " >
              <img className="img-fluid" src="../assets/images/hoist.png" style={{ width: '100px', height: '100px', objectFit: 'cover' }} alt="" />
              <Link to={'/blog1'} className="h5 d-flex align-items-center bg-light px-3 mb-0 w-[100%]">Patient lifter Hoist</Link>
            </div>
            <div className="d-flex rounded overflow-hidden mb-3" >
              <img className="img-fluid" src="../assets/gallery/gallery (99).JPG" style={{ width: '100px', height: '100px', objectFit: 'cover' }} alt="" />
              <Link to={'/blog2'} className="h5 d-flex align-items-center bg-light px-3 mb-0  w-[100%]">Nurturing Well-being</Link>
            </div>
            <div className="d-flex rounded overflow-hidden mb-3" >
              <img className="img-fluid" src="../assets/gallery/gallery (12).JPG" style={{ width: '100px', height: '100px', objectFit: 'cover' }} alt="" />
              <Link to={'/blog3'} className="h5 d-flex align-items-center bg-light px-3 mb-0  w-[100%]">Internships in Age Care Homes</Link>
            </div>
            {/* <div className="d-flex rounded overflow-hidden mb-3">
              <img className="img-fluid" src="../assets/images/blog-2.jpg" style={{ width: '100px', height: '100px', objectFit: 'cover' }} alt="" />
              <a href="" className="h5 d-flex align-items-center bg-light px-3 mb-0">Lorem ipsum dolor sit amet consec adipis elit
              </a>
            </div>
            <div className="d-flex rounded overflow-hidden mb-3">
              <img className="img-fluid" src="../assets/images/blog-3.jpg" style={{ width: '100px', height: '100px', objectFit: 'cover' }} alt="" />
              <a href="" className="h5 d-flex align-items-center bg-light px-3 mb-0">Lorem ipsum dolor sit amet consec adipis elit
              </a>
            </div>
            <div className="d-flex rounded overflow-hidden mb-3">
              <img className="img-fluid" src="../assets/images/blog-1.jpg" style={{ width: '100px', height: '100px', objectFit: 'cover' }} alt="" />
              <a href="" className="h5 d-flex align-items-center bg-light px-3 mb-0">Lorem ipsum dolor sit amet consec adipis elit
              </a>
            </div>
            <div className="d-flex rounded overflow-hidden mb-3">
              <img className="img-fluid" src="../assets/images/blog-2.jpg" style={{ width: '100px', height: '100px', objectFit: 'cover' }} alt="" />
              <a href="" className="h5 d-flex align-items-center bg-light px-3 mb-0">Lorem ipsum dolor sit amet consec adipis elit
              </a>
            </div> */}
          </div>
          {/* Recent Post End */}
          {/* Image Start */}
          <div className="mb-5">
            <img src="../assets/gallery/gallery (95).JPG" alt="" className="img-fluid rounded" />
          </div>
          {/* Image End */}
          {/* Tags Start */}
          {/* <div className="mb-5">
            <h4 className="d-inline-block text-primary text-uppercase border-bottom   mb-4">Tag Cloud</h4>
            <div className="d-flex flex-wrap m-n1">
              <a href="" className="btn btn-primary m-1">Design</a>
              <a href="" className="btn btn-primary m-1">Development</a>
              <a href="" className="btn btn-primary m-1">Marketing</a>
              <a href="" className="btn btn-primary m-1">SEO</a>
              <a href="" className="btn btn-primary m-1">Writing</a>
              <a href="" className="btn btn-primary m-1">Consulting</a>
              <a href="" className="btn btn-primary m-1">Design</a>
              <a href="" className="btn btn-primary m-1">Development</a>
              <a href="" className="btn btn-primary m-1">Marketing</a>
              <a href="" className="btn btn-primary m-1">SEO</a>
              <a href="" className="btn btn-primary m-1">Writing</a>
              <a href="" className="btn btn-primary m-1">Consulting</a>
            </div>
          </div> */}
          {/* Tags End */}
          {/* Plain Text Start */}
          {/* <div>
            <h4 className="d-inline-block text-primary text-uppercase border-bottom   mb-4">Plain Text</h4>
            <div className="bg-light rounded text-center" style={{ padding: '30px' }}>
              <p>Vero sea et accusam justo dolor accusam lorem consetetur, dolores sit amet sit dolor clita kasd justo, diam accusam no sea ut tempor magna takimata, amet sit et diam dolor ipsum amet diam</p>
              <a href="/" className="btn btn-primary py-2 px-4">Read More</a>
            </div>
          </div> */}
          {/* Plain Text End */}
        </div>
        {/* Sidebar End */}
      </div>
    </div>
    {/* Blog End */}
    
  {/* </div> */}
  </>
);
};

export default BlogDetail2;
