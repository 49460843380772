import React, { useState, useEffect } from 'react';

const Gallery = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationLimit, setPaginationLimit] = useState(5);

  // Simulated data for the gallery
  const totalImages = 142;
  const imagesPerPage = 12;
  const totalPages = Math.ceil(totalImages / imagesPerPage);

  useEffect(() => {
    // Adjust pagination limit based on window width
    const handleWindowResize = () => {
      const width = window.innerWidth;
      if (width <= 500) {
        setPaginationLimit(3);
      } else {
        setPaginationLimit(5);
      }
    };

    handleWindowResize(); // Initialize pagination limit based on initial window width
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []); // Empty dependency array ensures this effect runs only once on mount

  const openModal = (imageSrc) => {
    setSelectedImage(imageSrc);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  // Close modal when escape key is pressed
  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === 'Escape') {
        closeModal();
      }
    };

    window.addEventListener('keydown', handleEscapeKey);

    return () => {
      window.removeEventListener('keydown', handleEscapeKey);
    };
  }, []); // Adding an empty dependency array ensures this effect only runs once on component mount

  // Calculate index range of images to display for the current page
  const indexOfLastImage = currentPage * imagesPerPage;
  const indexOfFirstImage = indexOfLastImage - imagesPerPage;

  const displayPages = [];
  let startPage = Math.max(1, currentPage - Math.floor(paginationLimit / 2));
  let endPage = Math.min(totalPages, startPage + paginationLimit - 1);

  if (totalPages <= paginationLimit) {
    startPage = 1;
    endPage = totalPages;
  } else if (currentPage <= Math.ceil(paginationLimit / 2)) {
    endPage = paginationLimit;
  } else if (currentPage >= totalPages - Math.floor(paginationLimit / 2)) {
    startPage = totalPages - paginationLimit + 1;
  }

  for (let i = startPage; i <= endPage; i++) {
    displayPages.push(i);
  }
  return (
    <section className='my-10 mx-4 overflow-hidden '>
      {selectedImage && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50 ">
          <div className="relative w-2/3 h-2/3">
            <img
              src={selectedImage}
              alt="Selected"
              className="object-cover w-full h-full"
            />
            <button
              onClick={closeModal}
              className="absolute top-0 right-0 text-white text-lg font-bold bg-red-700 px-3 py-2"
            >
              <i class="fa-solid fa-xmark"></i>
            </button>
          </div>
        </div>
      )}

      <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
        {[...Array(imagesPerPage).keys()].map((index) => {
          const imageIndex = indexOfFirstImage + index + 1;
          const imagePath = `../assets/gallery/gallery (${imageIndex}).JPG`;

          // Check if image exists
          const imageExists = new Promise((resolve, reject) => {
            const img = new Image();
            img.src = imagePath;
            img.onload = () => resolve(true);
            img.onerror = () => resolve(false);
          });

          return (
            <div key={imageIndex} className="grid gap-4">
              <div>
                {imageExists && (
                  <img
                    className="h-auto max-w-full rounded-lg cursor-pointer transform hover:scale-105 transition duration-300"
                    src={imagePath}
                    alt={``}
                    onClick={() => openModal(imagePath)}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>

      <nav
        className=" page_margin flex items-center justify-between max-sm:justify-center max-sm:items-center max-md:px-2 pt-4 max-md:flex-col max-sm:text-sm"
        aria-label="Gallery navigation"
      >
        <span className="text-sm font-normal text-gray-500 max-sm:mx-auto dark:text-gray-400 mb-4 sm:mb-0 block  sm:inline sm:w-auto">
          Showing{' '}
          <span className="font-semibold text-gray-900 dark:text-white">
            {indexOfFirstImage + 1}-{Math.min(indexOfLastImage, totalImages)}
          </span>{' '}
          of{' '}
          <span className="font-semibold text-gray-900 dark:text-white">
            {totalImages}
          </span>
        </span>
        <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8 ">
          <li>
            <button
              onClick={() => setCurrentPage((prevPage) => prevPage - 1)}
              disabled={currentPage === 1}
              className="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-[color:var(--main)]"
            >
              Previous
            </button>
          </li>
          {displayPages.map((pageNumber) => (
            <li key={pageNumber}>
              <button
                onClick={() => setCurrentPage(pageNumber)}
                className={`flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-[color:var(--main)] ${
                  currentPage === pageNumber ? 'font-semibold text-[color:var(--main)]' : ''
                }`}
              >
                {pageNumber}
              </button>
            </li>
          ))}
          <li>
            <button
              onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
              disabled={indexOfLastImage >= totalImages}
              className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-[color:var(--main)]"
            >
              Next
            </button>
          </li>
        </ul>
      </nav>
      <div className='space-only'></div>
    </section>
    
  );
};

export default Gallery;
