import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/navbar';
import Footer from './components/footer';
import Home from './pages/home';
import About from './pages/about';
import Contact from './pages/contact';
import Courses from './pages/courses';
import Blog from './pages/blog';
import Gallery from './pages/gallery';
import Appointment from './pages/appointment';
import Extra from './pages/extra';
import Success from './pages/success';
import EnrollmentPage from './pages/enrollment_form';
import BlogDetail1 from './pages/blogs/blog1';
import BlogDetail2 from './pages/blogs/blog2';
import BlogDetail3 from './pages/blogs/blog3';
import CourseDetail1 from './pages/courses/courses_1';
import CourseDetail2 from './pages/courses/courses_2';
import CourseDetail3 from './pages/courses/courses_3';
import CourseDetail4 from './pages/courses/courses_4';
import CourseDetail5 from './pages/courses/courses_5';
import CourseDetail6 from './pages/courses/courses_6';
import CourseDetail7 from './pages/courses/courses_7';
import CourseDetail8 from './pages/courses/courses_8';
import CourseDetail9 from './pages/courses/courses_9';
import CourseDetail10 from './pages/courses/courses_10';
import CourseDetail11 from './pages/courses/courses_11';
import Analytics from './analytics';


function App() {
  return (
    <Router>
      <Analytics />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/enquiry" element={<Contact />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/SuccessResult" element={<Success />} />
        <Route path="/appointment" element={<Appointment />} />
        <Route path="/blog1" element={<BlogDetail1 />} />
        <Route path="/blog2" element={<BlogDetail2 />} />
        <Route path="/blog3" element={<BlogDetail3 />} />
        <Route path="/Course1" element={<CourseDetail1 />} />
        <Route path="/Course2" element={<CourseDetail2 />} />
        <Route path="/Course3" element={<CourseDetail3 />} />
        <Route path="/Course4" element={<CourseDetail4 />} />
        <Route path="/Course5" element={<CourseDetail5 />} />
        <Route path="/Course6" element={<CourseDetail6 />} />
        <Route path="/Course7" element={<CourseDetail7 />} />
        <Route path="/Course8" element={<CourseDetail8 />} />
        <Route path="/Course9" element={<CourseDetail9 />} />
        <Route path="/Course10" element={<CourseDetail10 />} />
        <Route path="/Course11" element={<CourseDetail11 />} />
        <Route path="/extra" element={<Extra />} />
        <Route path="/enrollment" element={<EnrollmentPage />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;