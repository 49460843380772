import React, { useState, useEffect } from 'react';

const Success = () => {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationLimit, setPaginationLimit] = useState(5);

  useEffect(() => {
    fetchData();
  }, []); // Fetch data on component mount

  useEffect(() => {
    // Adjust pagination limit when table height reaches maximum
    if (document.getElementById('table-wrapper').clientHeight > 800) {
      setPaginationLimit(3);
    } else {
      setPaginationLimit(5);
    }
  }, [data, currentPage]); // Re-run when data or current page changes

  const fetchData = async () => {
    try {
      const response = await fetch('https://sheetdb.io/api/v1/ihbm4bzaoxxqo');
      const jsonData = await response.json();
      // Replace empty values with "NA"
      const updatedData = jsonData.map((item) => ({
        ...item,
        Name: item.NAME || 'NA',
        Level: item.LEVEL || 'NA',
        Certificate_Number: item['CERTIFICATE NUMBER'] || 'NA',
        Date_Level_1: item['DATE LEVEL 1'] || 'NA',
        Date_Level_2: item['DATE LEVEL 2'] || 'NA',
        Date_Level_3: item['DATE LEVEL 3'] || 'NA',
        Date_Level_4: item['DATE LEVEL 4'] || 'NA',
      }));
      setData(updatedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSearchChange = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setCurrentPage(1); // Reset to the first page when rows per page changes
  };

  // Filter and paginate the data
  const filteredData = data.filter((item) => {
    const searchString = searchTerm.toLowerCase();
    return (
      item.Name.toLowerCase().includes(searchString) ||
      item.Level.toLowerCase().includes(searchString) ||
      item.Certificate_Number.toLowerCase().includes(searchString) ||
      item.Date_Level_1.toLowerCase().includes(searchString) ||
      item.Date_Level_2.toLowerCase().includes(searchString) ||
      item.Date_Level_3.toLowerCase().includes(searchString) ||
      item.Date_Level_4.toLowerCase().includes(searchString)
    );
  });

  const indexOfLastItem = currentPage * rowsPerPage;
  const indexOfFirstItem = indexOfLastItem - rowsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const displayPages = [];
  let startPage = Math.max(1, currentPage - Math.floor(paginationLimit / 2));
  let endPage = Math.min(totalPages, startPage + paginationLimit - 1);

  if (totalPages <= paginationLimit) {
    startPage = 1;
    endPage = totalPages;
  } else if (currentPage <= Math.ceil(paginationLimit / 2)) {
    endPage = paginationLimit;
  } else if (currentPage >= totalPages - Math.floor(paginationLimit / 2)) {
    startPage = totalPages - paginationLimit + 1;
  }

  for (let i = startPage; i <= endPage; i++) {
    displayPages.push(i);
  }

  

  return (
    <div id="table-wrapper" className="relative overflow-x-auto shadow-md sm:rounded-lg p-5 table_resize max-sm:text-sm">
      <div className="flex justify-between items-center mb-4 search-row">
        <div className="flex items-center ">
          <select
            className="border py-2 pl-4 rounded-md"
            value={rowsPerPage}
            onChange={handleRowsPerPageChange}
          >
            <option value={10}>10 Rows</option>
            <option value={20}>20 Rows</option>
            <option value={50}>50 Rows</option>
          </select>
        </div>
        <div>
          <input
            type="text"
            placeholder="Search..."
            className="border p-2 rounded-md mr-4"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          {/* <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
            Search
          </button> */}
        </div>
      </div>

      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 shadow-md">
        <thead className="text-xs  uppercase  bg-[color:var(--secondary-background)] text-[color:var(--primary-text)] ">
          <tr>
            <th scope="col" className="px-6 py-3">
              NAME
            </th>
            <th scope="col" className="px-6 py-3">
              LEVEL
            </th>
            <th scope="col" className="px-6 py-3">
              CERTIFICATE NUMBER
            </th>
            {/* <th scope="col" className="px-6 py-3">
              DATE LEVEL 1
            </th>
            <th scope="col" className="px-6 py-3">
              DATE LEVEL 2
            </th>
            <th scope="col" className="px-6 py-3">
              DATE LEVEL 3
            </th>
            <th scope="col" className="px-6 py-3">
              DATE LEVEL 4
            </th> */}
          </tr>
        </thead>

        <tbody>
          {currentData.map((item, index) => (
            <tr
              key={index}
              className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
            >
              <th
                scope="row"
                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
              >
                {item.Name}
              </th>
              <td className="px-6 py-4">{item.Level}</td>
              <td className="px-6 py-4">{item.Certificate_Number}</td>
              {/* <td className="px-6 py-4">{item.Date_Level_1}</td>
              <td className="px-6 py-4">{item.Date_Level_2}</td>
              <td className="px-6 py-4">{item.Date_Level_3}</td>
              <td className="px-6 py-4">{item.Date_Level_4}</td> */}
            </tr>
          ))}
        </tbody>
      </table>

      <nav
        className="flex items-center flex-wrap justify-between max-sm:justify-center max-sm:items-center  max-md:px-2 pt-4 "
        aria-label="Table navigation"
      >
        <span className="text-sm font-normal text-gray-500 max-sm:mx-auto dark:text-gray-400 mb-4 sm:mb-0 block  sm:inline sm:w-auto">
          Showing{' '}
          <span className="font-semibold text-gray-900 dark:text-white">
            {indexOfFirstItem + 1}-{Math.min(indexOfLastItem, filteredData.length)}
          </span>{' '}
          of{' '}
          <span className="font-semibold text-gray-900 dark:text-white">
            {filteredData.length}
          </span>
        </span>
        <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
          <li>
            <button
              onClick={() => setCurrentPage((prevPage) => prevPage - 1)}
              disabled={currentPage === 1}
              className="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-[color:var(--main)]"
            >
              Previous
            </button>
          </li>
          {displayPages.map((pageNumber) => (
            <li key={pageNumber}>
              <button
                onClick={() => setCurrentPage(pageNumber)}
                className={`flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-[color:var(--main)] ${
                  currentPage === pageNumber ? 'font-semibold text-gray-900 dark:text-[color:var(--main)]' : ''
                }`}
              >
                {pageNumber}
              </button>
            </li>
          ))}
          <li>
            <button
              onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
              disabled={indexOfLastItem >= filteredData.length}
              className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-[color:var(--main)]"
            >
              Next
            </button>
          </li>
        </ul>
      </nav>
    </div>
    
  );
};

export default Success;
