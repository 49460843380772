// import React, { useEffect, useRef } from "react";
import { Link, useLocation } from 'react-router-dom'; 
const Courses = () => {
    const course = [
        {
            title: "Certificate I affiliated to CTEVT",
            image: "../assets/images/course_2.jpg",
            link: '/Course1',
            features: [
                "Completion hours: 390",
                "6 grade-based skills chapters✅",
                // "NRP 30,000",
                // "Theory classes",
                // "Practical classes",
                // "Internship",
                // "OJT preparation",
                // "Test✅",
            ],
            tprice: "",
            dprice:  "NPR 30,000",
        },
        {
            title: "Basic CNA (Certified Nursing Assistant) Certificate II",
            image: "../assets/images/course_1.jpg",
            link: '/Course2',
            features: [
                "Completion hours: 56",
                "36+ grade-based chapters✅",
                // "NRP 75,000",
                // "Theory classes",
                // "Practical classes",
                // "Demonstration",
                // "Internship",
                // "OJT preparation",
                // "Test✅",
            ],
            tprice: "",
            dprice:  "A$864",
        },
        {
            title: "Certificate III in Community Service",
            image: "../assets/images/course_3.jpg",
            link: '/Course3',
            features: [
                "Completion hours: 390",
                "Cognitive Behavior Therapy (CBT)",
                // "NRP 1,25,000",
                // "Psychological & Physical Well-being",
                // "65+ geriatric grade-based chapters",
                // "Theory classes",
                // "Practical classes",
                // "Demonstration with foreign equipment",
                // "Internship",
                // "OJT preparation",
                // "Test✅",
            ],
            tprice: "A$8300",
            dprice:  "A$1439",
        },
        {
            title: "Certificate IV in Individual Support (Supervisor Level)",
            image: "../assets/images/course_4.jpg",
            link: '/Course4',
            features: [
                "Duration: 26 weeks",
                "Supervisor Level Training",
                // "NRP 1,70,000",
                // "Desk work & Independence",
                // "21+ Individual Support Techniques✅",
                // "Demonstration with foreign equipment",
            ],
            tprice: "A$8900",
            dprice:  "A$1957",
        },
        
    ];
    return (
      <>
        <section className='w-[75%] max-xl:w-[85%] max-md:w-[95%] mx-auto my-20'>
      <div className='flex flex-wrap gap-4'>
    {course.map((course,index) =>(
      <div class=" bg-white border border-gray-200 rounded-lg basis-[30%] max-lg:basis-[48%] max-sm:basis-[98%]" key={index}>
          <Link to={course.link}>
              <img class="rounded-t-lg" src={course.image} alt="" />
          </Link>
          <div class="p-4">
              <Link to={course.link}>
                  <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 line-clamp-2 h-16">{course.title}</h5>
              </Link>
              {course.features.map((feature, cont) => (
                    <li key={cont}>{feature}</li>
                  ))}
                
                <p className='uppercase  text-xl my-2 text-[color:#ffb703] font-bold'>certificate</p>
              <Link to={course.link} class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                  Read more
                  <svg class="rtl:rotate-180 w-3.5 h-3.5 ms-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                  </svg>
              </Link>
          </div>
      </div>
    ))}

      </div>
    </section>
      </>
    );
  };
  export default Courses;
  