import React from "react";
import { Link, useLocation } from 'react-router-dom'; 
const Footer = () => {
    return (
        <>
            {/* <footer>
                <div className="footer-up">
                    <div className="footer-logo">
                        <img src="../assets/images/isha_logo.png" alt="logo" />
                    </div>
                    <div className="site-map">
                        <h4>Sitemap</h4>
                        <a href="/">Home</a>
                        <a href="/about">Courses</a>
                        <a href="/">Contact</a>
                    </div>
                    <div className="socials">
                        <h4>Socials</h4>
                        <a href="https://www.facebook.com/ishahelpline/" target="_blank" rel="noopener noreferrer">Facebook</a>
                        <a href="https://www.linkedin.com/in/isha-caregivers-353782263/" target="_blank" rel="noopener noreferrer">Linkedin</a>
                        <a href="https://www.instagram.com/ishacaregivers/" target="_blank" rel="noopener noreferrer">Instagram</a>
                        <a href="https://www.youtube.com/channel/UCx_3efeclUta8-l6A3qq0MQ" target="_blank" rel="noopener noreferrer">Youtube</a>
                    </div>

                    <div className="office">
                        <h4>Head Office</h4>
                        <p>Putalisadak-32, Kathmandu, Nepal</p>
                    </div>
                </div>
                <div className="footer-buttom">
                    <div className="footer-buttom-link">
                        <a href="mailto:ishahelpline@gmail.com">ishahelpline@gmail.com</a>
                    </div>
                    <div className="footer-buttom-link">
                        <a href="tel:15913135">+977-1-5913135</a>
                    </div>
                    <p>© 2024 L2024 - Isha Helpline. All rights reserved.</p>
                </div>
            </footer> */}


<footer class="bg-[color:var(--footer)]">
  <div class="mx-auto max-w-screen-xl px-4 pb-6 pt-16 sm:px-6 lg:px-8 lg:pt-24">
    <div class="grid grid-cols-1 gap-8 lg:grid-cols-3">
      <div>
        <div class="flex justify-center items-center text-[color:var(--primary-text)] font-bold sm:justify-start">
          <img src="../assets/images/isha_logo.png" className="w-32" alt="" />
          <p>Isha Helpline Caregiver <br />
Training & Research Center</p>
        </div>

        

        <ul class="mt-8 flex justify-center gap-6 sm:justify-start md:gap-8">
          <li>
            <a
              href="#"
              rel="noreferrer"
              target="_blank"
              class="text-[color:var(--main)] transition hover:text-teal-700/75"
            >
              <span class="sr-only">Facebook</span>
              <i class="fa-brands fa-facebook text-2xl"></i>
            </a>
          </li>

          <li>
            <a
              href="#"
              rel="noreferrer"
              target="_blank"
              class="text-[color:var(--main)] transition hover:text-teal-700/75"
            >
              <span class="sr-only">Instagram</span>
              <i class="fa-brands fa-instagram text-2xl"></i>
            </a>
          </li>
          <li>
            <a
              href="#"
              rel="noreferrer"
              target="_blank"
              class="text-[color:var(--main)] transition hover:text-teal-700/75"
            >
              <span class="sr-only">LinkedIn</span>
              <i class="fa-brands fa-linkedin text-2xl"></i>
            </a>
          </li>
          <li>
            <a
              href="#"
              rel="noreferrer"
              target="_blank"
              class="text-[color:var(--main)] transition hover:text-teal-700/75"
            >
              <span class="sr-only">Youtube</span>
              <i class="fa-brands fa-youtube text-2xl"></i>
            </a>
          </li>

          {/* <li>
            <a
              href="#"
              rel="noreferrer"
              target="_blank"
              class="text-teal-700 transition hover:text-teal-700/75"
            >
              <span class="sr-only">Twitter</span>
              <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path
                  d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"
                />
              </svg>
            </a>
          </li>

          <li>
            <a
              href="#"
              rel="noreferrer"
              target="_blank"
              class="text-teal-700 transition hover:text-teal-700/75"
            >
              <span class="sr-only">GitHub</span>
              <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path
                  fill-rule="evenodd"
                  d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>
          </li>

          <li>
            <a
              href="#"
              rel="noreferrer"
              target="_blank"
              class="text-teal-700 transition hover:text-teal-700/75"
            >
              <span class="sr-only">Dribbble</span>
              <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path
                  fill-rule="evenodd"
                  d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm6.605 4.61a8.502 8.502 0 011.93 5.314c-.281-.054-3.101-.629-5.943-.271-.065-.141-.12-.293-.184-.445a25.416 25.416 0 00-.564-1.236c3.145-1.28 4.577-3.124 4.761-3.362zM12 3.475c2.17 0 4.154.813 5.662 2.148-.152.216-1.443 1.941-4.48 3.08-1.399-2.57-2.95-4.675-3.189-5A8.687 8.687 0 0112 3.475zm-3.633.803a53.896 53.896 0 013.167 4.935c-3.992 1.063-7.517 1.04-7.896 1.04a8.581 8.581 0 014.729-5.975zM3.453 12.01v-.26c.37.01 4.512.065 8.775-1.215.25.477.477.965.694 1.453-.109.033-.228.065-.336.098-4.404 1.42-6.747 5.303-6.942 5.629a8.522 8.522 0 01-2.19-5.705zM12 20.547a8.482 8.482 0 01-5.239-1.8c.152-.315 1.888-3.656 6.703-5.337.022-.01.033-.01.054-.022a35.318 35.318 0 011.823 6.475 8.4 8.4 0 01-3.341.684zm4.761-1.465c-.086-.52-.542-3.015-1.659-6.084 2.679-.423 5.022.271 5.314.369a8.468 8.468 0 01-3.655 5.715z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>
          </li> */}
        </ul>
      </div>

      <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-4 lg:col-span-2">
        <div class="text-center sm:text-left">
          <p class="text-lg font-medium text-[color:var(--primary-text)]">Courses</p>

          <ul class="mt-8 space-y-4 text-sm">
            <li>
              <Link class="text-gray-700 transition hover:text-gray-700/75" to="Course1">
              Certificate I
              </Link>
            </li>

            <li>
              <Link class="text-gray-700 transition hover:text-gray-700/75" to="Course2">
              Certificate II
              </Link>
            </li>

            <li>
              <Link class="text-gray-700 transition hover:text-gray-700/75" to="Course3">
              Certificate III
              </Link>
            </li>

            <li>
              <Link class="text-gray-700 transition hover:text-gray-700/75" to="Course4"> Certificate IV </Link>
            </li>
          </ul>
        </div>

        <div class="text-center sm:text-left">
          <p class="text-lg font-medium text-[color:var(--primary-text)]">Explore</p>

          <ul class="mt-8 space-y-4 text-sm">
            <li>
              <Link class="text-gray-700 transition hover:text-gray-700/75" to="appointment">
                Appointment
              </Link>
            </li>

            {/* <li>
              <Link class="text-gray-700 transition hover:text-gray-700/75" to="enrollment"> Enrollment </Link>
            </li> */}

            <li>
              <Link class="text-gray-700 transition hover:text-gray-700/75" to="blog"> Blog </Link>
            </li>

            <li>
              <Link class="text-gray-700 transition hover:text-gray-700/75" to="gallery"> Gallery </Link>
            </li>
          </ul>
        </div>

        <div class="text-center sm:text-left">
          <p class="text-lg font-medium text-[color:var(--primary-text)]">Helpful Links</p>

          <ul class="mt-8 space-y-4 text-sm">
            <li>
              <Link class="text-gray-700 transition hover:text-gray-700/75" to="SuccessResult"> Success Result </Link>
            </li>

            <li>
              <Link class="text-gray-700 transition hover:text-gray-700/75" to="enquiry"> Enquiry</Link>
            </li>

            {/* <li>
              <a
                class="group flex justify-center gap-1.5 ltr:sm:justify-start rtl:sm:justify-end"
                href="#"
              >
                <span class="text-gray-700 transition group-hover:text-gray-700/75">
                  Live Chat
                </span>

                <span class="relative flex h-2 w-2">
                  <span
                    class="absolute inline-flex h-full w-full animate-ping rounded-full bg-teal-400 opacity-75"
                  ></span>
                  <span class="relative inline-flex size-2 rounded-full bg-teal-500"></span>
                </span>
              </a>
            </li> */}
          </ul>
        </div>

        <div class="text-center sm:text-left">
          <p class="text-lg font-medium text-[color:var(--primary-text)]">Contact Us</p>

          <ul class="mt-8 space-y-4 text-sm">
            <li>
              <a
                class="flex items-center justify-center gap-1.5 ltr:sm:justify-start rtl:sm:justify-end"
                href="mailto:ishahelpline@gmail.com"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="size-5 shrink-0 text-gray-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>

                <span class="flex-1 text-gray-700">ishahelpline@gmail.com</span>
              </a>
            </li>

            <li>
              <a
                class="flex items-center justify-center gap-1.5 ltr:sm:justify-start rtl:sm:justify-end"
                href="tel:015913135"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="size-5 shrink-0 text-gray-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  />
                </svg>

                <span class="flex-1 text-gray-700">+977-1-5913135</span>
              </a>
            </li>

            <li
              class="flex items-start justify-center gap-1.5 ltr:sm:justify-start rtl:sm:justify-end"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="size-5 shrink-0 text-gray-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>

              <address class="-mt-0.5 flex-1 not-italic text-gray-700">
                Putalisadak, Kathmandu
              </address>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="mt-12 border-t border-gray-100 pt-6">
      <div class="text-center sm:flex sm:justify-between sm:text-left">
        <p class="text-sm text-gray-500">
          <span class="block sm:inline">All rights reserved.</span>

          {/* <a
            class="inline-block text-teal-600 underline transition hover:text-teal-600/75"
            href="#"
          >
            Terms & Conditions
          </a> */}

          {/* <span>&middot;</span> */}

          {/* <a
            class="inline-block text-teal-600 underline transition hover:text-teal-600/75"
            href="#"
          >
            Privacy Policy
          </a> */}
        </p>

        <p class="mt-4 text-sm text-gray-500 sm:order-first sm:mt-0">&copy; 2024 Isha Helpline Caregiver Training & Research Center</p>
      </div>
    </div>
  </div>
</footer>
        </>
    )
}

export default Footer