import React from 'react';
import { Link, useLocation } from 'react-router-dom'; 

const BlogDetail1 = () => {
  return (
    <>
  
    <div className="container py-5">
      <div className="row g-5">
        <div className="col-lg-8">
          {/* Blog Detail Start */}
          <div className="mb-5">
            <img className="img-fluid w-100 rounded mb-5" src="../assets/images/hoist.png" alt="" />
            <h1 className="mb-4" id='blog-text-color'>Patient lifter Hoist</h1>
            <p>A patient lifter is a piece of medical equipment designed to assist caregivers in safely transferring patients who have limited mobility or are unable to move independently. It typically consists of a hydraulic or electric-powered lift mechanism attached to a sling or harness. Caregivers can use the lifter to raise, lower, and move patients between different surfaces such as beds, chairs, or wheelchairs, reducing the risk of injury to both the patient and the caregiver.</p>

            <p>Isha Helpline Caregiver Training & Research Center is unique in offering caregiver training valid internationally for the use of patient lifters. This means that their training program meets recognized standards and regulations for caregiver training in multiple countries around the world. By providing such training, Isha ensures that caregivers have the necessary skills and knowledge to safely and effectively use patient lifters, improving the quality of care for patients while reducing the risk of accidents or injuries during transfers. This international validity of their training program suggests a high level of expertise and adherence to global standards in the field of patient care. Isha is the only institute which provides training services with hoist in today’s date. Hoist was brought up in Nepal by Isha Helpline Caregiver Training & Research Center in 2023, February 7th. Although this technology is familiar in countries like Australia, England, Europe, Canada, America, etc, In case of Nepal, Isha is the only training center that started age care opportunities in Nepal and provide training with Modern equipment like Patient Lifter Hoist, Shower Chair, Wheel Chair, Transfer Belt, Walker, Transfer Chair. In case of Caregiving Isha expects expertise in learning with availability of Mobility Aids, Bathroom Safety equipment, Patient Lifter and Transfer equipment, Medical Monitoring equipment, Assistive Device, Safety Equipment, Daily Living Aid, Personal Care Supplies, Rehabilitation Equipment, Cleaning and Maintenance Equipment, and the best learning environment.</p>

            <p>This pioneering initiative by Isha Helpline Caregiver Training & Research Center marks a significant advancement in the provision of elder care services in Nepal. By introducing modern equipment and internationally validated training programs, Isha is not only addressing the immediate needs of patients and caregivers but also setting a precedent for quality care standards in the country. The inclusion of patient lifter hoists and other advanced equipment underscores Isha's commitment to enhancing the quality of life for elderly individuals and those with limited mobility. Through their comprehensive training programs and access to cutting-edge technology, Isha is poised to make a lasting impact on the caregiving landscape in Nepal, ensuring that caregivers are equipped with the necessary skills and resources to provide compassionate and effective care to those in need.</p>
            <p>In doing so, Isha Helpline Caregiver Training & Research Center is not only bridging a crucial gap in elder care services but also laying the groundwork for a more inclusive and supportive healthcare ecosystem in Nepal.</p>
            {/* <div className="d-flex justify-content-between bg-light rounded p-4 mt-4 mb-4">
              <div className="d-flex align-items-center">
                <img className="rounded-circle me-2" src="../assets/images/user.jpg" width="40" height="40" alt="" />
                <span>John Doe</span>
              </div>
              <div className="d-flex align-items-center">
                <span className="ms-3"><i className="far fa-eye text-primary me-1"></i>12345</span>
                <span className="ms-3"><i className="far fa-comment text-primary me-1"></i>123</span>
              </div>
            </div> */}
          </div>
          {/* Blog Detail End */}
          {/* Comment List Start */}
          {/* <div className="mb-5">
            <h4 className="d-inline-block text-primary text-uppercase border-bottom   mb-4">3 Comments</h4>
            <div className="d-flex mb-4">
              <img src="../assets/images/user.jpg" className="img-fluid rounded-circle" style={{ width: '45px', height: '45px' }} alt="" />
              <div className="ps-3">
                <h6><a href="">John Doe</a> <small><i>01 Jan 2045</i></small></h6>
                <p>Diam amet duo labore stet elitr invidunt ea clita ipsum voluptua, tempor labore
                  accusam ipsum et no at. Kasd diam tempor rebum magna dolores sed eirmod</p>
                <button className="btn btn-sm btn-light">Reply</button>
              </div>
            </div>
            <div className="d-flex mb-4">
              <img src="../assets/images/user.jpg" className="img-fluid rounded-circle" style={{ width: '45px', height: '45px' }} alt="" />
              <div className="ps-3">
                <h6><a href="">John Doe</a> <small><i>01 Jan 2045</i></small></h6>
                <p>Diam amet duo labore stet elitr invidunt ea clita ipsum voluptua, tempor labore
                  accusam ipsum et no at. Kasd diam tempor rebum magna dolores sed eirmod</p>
                <button className="btn btn-sm btn-light">Reply</button>
              </div>
            </div>
            <div className="d-flex ms-5 mb-4">
              <img src="../assets/images/user.jpg" className="img-fluid rounded-circle" style={{ width: '45px', height: '45px' }} alt="" />
              <div className="ps-3">
                <h6><a href="">John Doe</a> <small><i>01 Jan 2045</i></small></h6>
                <p>Diam amet duo labore stet elitr invidunt ea clita ipsum voluptua, tempor labore
                  accusam ipsum et no at. Kasd diam tempor rebum magna dolores sed eirmod</p>
                <button className="btn btn-sm btn-light">Reply</button>
              </div>
            </div>
          </div> */}
          {/* Comment List End */}
          {/* Comment Form Start */}
          {/* <div className="bg-light rounded p-5">
            <h4 className="d-inline-block text-primary text-uppercase border-bottom   border-white mb-4">Leave a comment</h4>
            <form>
              <div className="row g-3">
                <div className="col-12 col-sm-6">
                  <input type="text" className="form-control bg-white border-0" placeholder="Your Name" style={{ height: '55px' }} />
                </div>
                <div className="col-12 col-sm-6">
                  <input type="email" className="form-control bg-white border-0" placeholder="Your Email" style={{ height: '55px' }} />
                </div>
                <div className="col-12">
                  <input type="text" className="form-control bg-white border-0" placeholder="Website" style={{ height: '55px' }} />
                </div>
                <div className="col-12">
                  <textarea className="form-control bg-white border-0" rows="5" placeholder="Comment"></textarea>
                </div>
                <div className="col-12">
                  <button className="btn btn-primary w-100 py-3" id='comment-button' type="submit">Leave Your Comment</button>
                </div>
              </div>
            </form>
          </div> */}
          {/* Comment Form End */}
        </div>
        {/* Sidebar Start */}
        <div className="col-lg-4">
          {/* Search Form Start */}
          {/* <div className="mb-5">
            <div className="input-group">
              <input type="text" className="form-control p-3" placeholder="Keyword" />
              <button className="btn btn-primary px-3"><i className="fa fa-search"></i></button>
            </div>
          </div> */}
          {/* Search Form End */}
          {/* Category Start */}
          <div className="mb-5">
            <h4 className="d-inline-block text-primary text-uppercase border-bottom   mb-4">Top Courses</h4>
            <div className="d-flex flex-column justify-content-start blog-courses">
              <Link to={'/course1'} className="h5 bg-light rounded py-2 px-3 mb-2" >Certificate I affiliated to CTEVT <i className="fa fa-angle-right me-2"></i></Link>
              <Link to={'/course2'} className="h5 bg-light rounded py-2 px-3 mb-2" >Basic CNA (Certified Nursing Assistant) Certificate II<i className="fa fa-angle-right me-2"></i></Link>
              <Link to={'/course3'} className="h5 bg-light rounded py-2 px-3 mb-2" >Certificate III in Community Service of Age Care<i className="fa fa-angle-right me-2"></i></Link>
              <Link to={'/course4'} className="h5 bg-light rounded py-2 px-3 mb-2" >Certificate IV in Individual Support for Age Care<i className="fa fa-angle-right me-2"></i></Link>
            </div>
          </div>
          {/* Category End */}
          {/* Recent Post Start */}
          <div className="mb-5 blog-courses">
            <h4 className="d-inline-block text-primary text-uppercase border-bottom   mb-4">Recent Post</h4>
            <div className="d-flex rounded overflow-hidden mb-3 " >
              <img className="img-fluid" src="../assets/images/hoist.png" style={{ width: '100px', height: '100px', objectFit: 'cover' }} alt="" />
              <Link to={'/blog1'} className="h5 d-flex align-items-center bg-light px-3 mb-0 w-[100%]">Patient lifter Hoist</Link>
            </div>
            <div className="d-flex rounded overflow-hidden mb-3" >
              <img className="img-fluid" src="../assets/gallery/gallery (99).JPG" style={{ width: '100px', height: '100px', objectFit: 'cover' }} alt="" />
              <Link to={'/blog2'} className="h5 d-flex align-items-center bg-light px-3 mb-0  w-[100%]">Nurturing Well-being</Link>
            </div>
            <div className="d-flex rounded overflow-hidden mb-3" >
              <img className="img-fluid" src="../assets/gallery/gallery (12).JPG" style={{ width: '100px', height: '100px', objectFit: 'cover' }} alt="" />
              <Link to={'/blog3'} className="h5 d-flex align-items-center bg-light px-3 mb-0  w-[100%]">Internships in Age Care Homes</Link>
            </div>
            {/* <div className="d-flex rounded overflow-hidden mb-3">
              <img className="img-fluid" src="../assets/images/blog-2.jpg" style={{ width: '100px', height: '100px', objectFit: 'cover' }} alt="" />
              <a href="" className="h5 d-flex align-items-center bg-light px-3 mb-0">Lorem ipsum dolor sit amet consec adipis elit
              </a>
            </div>
            <div className="d-flex rounded overflow-hidden mb-3">
              <img className="img-fluid" src="../assets/images/blog-3.jpg" style={{ width: '100px', height: '100px', objectFit: 'cover' }} alt="" />
              <a href="" className="h5 d-flex align-items-center bg-light px-3 mb-0">Lorem ipsum dolor sit amet consec adipis elit
              </a>
            </div>
            <div className="d-flex rounded overflow-hidden mb-3">
              <img className="img-fluid" src="../assets/images/blog-1.jpg" style={{ width: '100px', height: '100px', objectFit: 'cover' }} alt="" />
              <a href="" className="h5 d-flex align-items-center bg-light px-3 mb-0">Lorem ipsum dolor sit amet consec adipis elit
              </a>
            </div>
            <div className="d-flex rounded overflow-hidden mb-3">
              <img className="img-fluid" src="../assets/images/blog-2.jpg" style={{ width: '100px', height: '100px', objectFit: 'cover' }} alt="" />
              <a href="" className="h5 d-flex align-items-center bg-light px-3 mb-0">Lorem ipsum dolor sit amet consec adipis elit
              </a>
            </div> */}
          </div>
          {/* Recent Post End */}
          {/* Image Start */}
          <div className="mb-5">
            <img src="../assets/gallery/gallery (132).JPG" alt="" className="img-fluid rounded" />
          </div>
          {/* Image End */}
          {/* Tags Start */}
          {/* <div className="mb-5">
            <h4 className="d-inline-block text-primary text-uppercase border-bottom   mb-4">Tag Cloud</h4>
            <div className="d-flex flex-wrap m-n1">
              <a href="" className="btn btn-primary m-1">Design</a>
              <a href="" className="btn btn-primary m-1">Development</a>
              <a href="" className="btn btn-primary m-1">Marketing</a>
              <a href="" className="btn btn-primary m-1">SEO</a>
              <a href="" className="btn btn-primary m-1">Writing</a>
              <a href="" className="btn btn-primary m-1">Consulting</a>
              <a href="" className="btn btn-primary m-1">Design</a>
              <a href="" className="btn btn-primary m-1">Development</a>
              <a href="" className="btn btn-primary m-1">Marketing</a>
              <a href="" className="btn btn-primary m-1">SEO</a>
              <a href="" className="btn btn-primary m-1">Writing</a>
              <a href="" className="btn btn-primary m-1">Consulting</a>
            </div>
          </div> */}
          {/* Tags End */}
          {/* Plain Text Start */}
          {/* <div>
            <h4 className="d-inline-block text-primary text-uppercase border-bottom   mb-4">Plain Text</h4>
            <div className="bg-light rounded text-center" style={{ padding: '30px' }}>
              <p>Vero sea et accusam justo dolor accusam lorem consetetur, dolores sit amet sit dolor clita kasd justo, diam accusam no sea ut tempor magna takimata, amet sit et diam dolor ipsum amet diam</p>
              <a href="/" className="btn btn-primary py-2 px-4">Read More</a>
            </div>
          </div> */}
          {/* Plain Text End */}
        </div>
        {/* Sidebar End */}
      </div>
    </div>
    {/* Blog End */}
    
  {/* </div> */}
  </>
);
};

export default BlogDetail1;
