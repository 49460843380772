// import React, { useEffect, useRef } from "react";
import { Link, useLocation } from 'react-router-dom'; 
const Blog = () => {
    return (
      <>
        <section className="home-blog">
  <div className="container-fluid py-5">
    <div className="container">
      <div className="text-center mx-auto mb-5" style={{ maxWidth: '500px' }}>
        <h5 className="d-inline-block text-primary text-uppercase border-bottom">Blog Post</h5>
        <h1 className="display-4">Our Latest Blog</h1>
      </div>
      <div className="row g-5">
        {[
          {
            title: 'Patient lifter Hoist',
            description: 'A patient lifter is a piece of medical equipment designed to assist caregivers in safely transferring patients who have limited mobility or are unable to move independently.',
            imageSrc: '../assets/images/hoist.png',
            link: '/blog1',
          },
          {
            title: 'Nurturing Well-being',
            description: "Isha Helpline Caregiver Training & Research Center's Journey with Cognitive Behavior Therapy (CBT) in Elder Care",
            imageSrc: '../assets/gallery/gallery (99).JPG',
            link: '/blog2',
          },
          {
            title: 'Internships in Age Care Homes',
            description: 'Trainees of Isha share their experiences and the issues that they faced during their time in the aged care homes.',
            imageSrc: '../assets/gallery/gallery (12).JPG',
            link: '/blog3',
          },
        ].map((blog, index) => (
          <div key={index} className="col-xl-4 col-lg-6">
            <div className="bg-light rounded overflow-hidden">
              <img
                className="w-full !h-96  object-cover"
                src={blog.imageSrc}
                alt={blog.title}
                style={{ width: '100%', height: '200px', objectFit: 'cover' }}
              />
              <div className="p-4">
                <Link className="h3 d-block mb-3" to={blog.link}>{blog.title}</Link>
                <p className="m-0 line-clamp-2">{blog.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
</section>
      </>
    );
  };
  export default Blog;
  